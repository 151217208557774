/* eslint-disable */
// ⚠️⚠️⚠️⚠️⚠️
// This file was automatically generated and should not be edited.
// ⚠️⚠️⚠️⚠️⚠️

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: any; output: any; }
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: any; output: any; }
  /** Represents untyped JSON */
  JSON: { input: any; output: any; }
};

export type Accommodation = {
  __typename?: 'Accommodation';
  amountPaid?: Maybe<Scalars['String']['output']>;
  bookedOnline: Scalars['Boolean']['output'];
  cancelled: Scalars['Boolean']['output'];
  cc?: Maybe<Scalars['String']['output']>;
  ccCvv2?: Maybe<Scalars['String']['output']>;
  ccExpirationMonth?: Maybe<Scalars['String']['output']>;
  ccExpirationYear?: Maybe<Scalars['String']['output']>;
  ccHolder?: Maybe<Scalars['String']['output']>;
  ccType?: Maybe<CreditCardType>;
  confirmation?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  dietaryRequirements?: Maybe<Scalars['String']['output']>;
  earlyCheckIn: Scalars['Boolean']['output'];
  earlyCheckInTime?: Maybe<Scalars['String']['output']>;
  earlyCheckInType?: Maybe<CheckInOut>;
  endDate: Scalars['ISO8601Date']['output'];
  endTime?: Maybe<Scalars['String']['output']>;
  eventParticipation: EventParticipation;
  extrasPaid: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  kosher: Scalars['Boolean']['output'];
  lateCheckOut: Scalars['Boolean']['output'];
  lateCheckOutTime?: Maybe<Scalars['String']['output']>;
  lateCheckOutType?: Maybe<CheckInOut>;
  lodge?: Maybe<Lodge>;
  lodgeRoom?: Maybe<LodgeRoomQuantity>;
  loyaltyCardNumber?: Maybe<Scalars['String']['output']>;
  nightsPaid?: Maybe<Scalars['String']['output']>;
  paidStatus: PaidStatus;
  pricePerNightAmount?: Maybe<Scalars['Float']['output']>;
  pricePerNightCurrency?: Maybe<Currency>;
  roomCode?: Maybe<Scalars['String']['output']>;
  roomNumber?: Maybe<Scalars['String']['output']>;
  roomRequirements?: Maybe<Scalars['String']['output']>;
  sharedWith?: Maybe<EventParticipation>;
  smoking: Scalars['Boolean']['output'];
  startDate: Scalars['ISO8601Date']['output'];
  startTime?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type AdvancedFilter = {
  key: Scalars['String']['input'];
  operand: AdvancedFilterOperands;
  values: Array<Scalars['String']['input']>;
};

export enum AdvancedFilterOperands {
  Eq = 'EQ',
  Neq = 'NEQ'
}

export type Affiliation = {
  __typename?: 'Affiliation';
  affiliationType: AffiliationType;
  from: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  institution: Institution;
  relatedInstitution: Institution;
};

export type AffiliationCollection = {
  __typename?: 'AffiliationCollection';
  collection: Array<Affiliation>;
  export: CollectionExport;
  meta: CollectionMetadata;
};


export type AffiliationCollectionExportArgs = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  format?: InputMaybe<CollectionExportFormat>;
};

export type AffiliationType = {
  __typename?: 'AffiliationType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  wjcSpecific: Scalars['Boolean']['output'];
};

export type AffiliationTypeCollection = {
  __typename?: 'AffiliationTypeCollection';
  collection: Array<AffiliationType>;
  export: CollectionExport;
  meta: CollectionMetadata;
};


export type AffiliationTypeCollectionExportArgs = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  format?: InputMaybe<CollectionExportFormat>;
};

/** Autogenerated input type of ArchiveEventMutation */
export type ArchiveEventMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['ID']['input'];
};

/** Autogenerated return type of ArchiveEventMutation. */
export type ArchiveEventMutationPayload = {
  __typename?: 'ArchiveEventMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  event?: Maybe<Event>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of ArchiveFormMutation */
export type ArchiveFormMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  formId: Scalars['ID']['input'];
};

/** Autogenerated return type of ArchiveFormMutation. */
export type ArchiveFormMutationPayload = {
  __typename?: 'ArchiveFormMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  form?: Maybe<Form>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of ArchiveFormSubmissionMutation */
export type ArchiveFormSubmissionMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  formSubmissionId: Scalars['ID']['input'];
};

/** Autogenerated return type of ArchiveFormSubmissionMutation. */
export type ArchiveFormSubmissionMutationPayload = {
  __typename?: 'ArchiveFormSubmissionMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  formSubmission?: Maybe<FormSubmission>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of BatchAddParticipantsMutation */
export type BatchAddParticipantsMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['ID']['input'];
  peopleIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of BatchAddParticipantsMutation. */
export type BatchAddParticipantsMutationPayload = {
  __typename?: 'BatchAddParticipantsMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  event?: Maybe<Event>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of BatchAddParticipantsToGroupMutation */
export type BatchAddParticipantsToGroupMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventGroupId: Scalars['ID']['input'];
  participationIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of BatchAddParticipantsToGroupMutation. */
export type BatchAddParticipantsToGroupMutationPayload = {
  __typename?: 'BatchAddParticipantsToGroupMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of BatchAddParticipantsToSubEventMutation */
export type BatchAddParticipantsToSubEventMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  participationIds: Array<Scalars['ID']['input']>;
  subEventId: Scalars['ID']['input'];
};

/** Autogenerated return type of BatchAddParticipantsToSubEventMutation. */
export type BatchAddParticipantsToSubEventMutationPayload = {
  __typename?: 'BatchAddParticipantsToSubEventMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of BatchAddParticipantsWithTagMutation */
export type BatchAddParticipantsWithTagMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['ID']['input'];
  tags: Array<Scalars['String']['input']>;
};

/** Autogenerated return type of BatchAddParticipantsWithTagMutation. */
export type BatchAddParticipantsWithTagMutationPayload = {
  __typename?: 'BatchAddParticipantsWithTagMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  event?: Maybe<Event>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of BatchAddTagsPeopleMutation */
export type BatchAddTagsPeopleMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  peopleIds: Array<Scalars['ID']['input']>;
  tags: Array<Scalars['String']['input']>;
};

/** Autogenerated return type of BatchAddTagsPeopleMutation. */
export type BatchAddTagsPeopleMutationPayload = {
  __typename?: 'BatchAddTagsPeopleMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of BatchAssignCapacityToParticipantsMutation */
export type BatchAssignCapacityToParticipantsMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventCapacityId: Scalars['ID']['input'];
  participationIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of BatchAssignCapacityToParticipantsMutation. */
export type BatchAssignCapacityToParticipantsMutationPayload = {
  __typename?: 'BatchAssignCapacityToParticipantsMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of BatchChangeParticipationStatusesMutation */
export type BatchChangeParticipationStatusesMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  subEventParticipations: Array<SubEventParticipationStatusForBatch>;
};

/** Autogenerated return type of BatchChangeParticipationStatusesMutation. */
export type BatchChangeParticipationStatusesMutationPayload = {
  __typename?: 'BatchChangeParticipationStatusesMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of BatchChangeSubEventParticipationStatusMutation */
export type BatchChangeSubEventParticipationStatusMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  participationIds: Array<Scalars['ID']['input']>;
  status: BatchChangeSubEventTargetStatus;
  subEventId: Scalars['ID']['input'];
};

/** Autogenerated return type of BatchChangeSubEventParticipationStatusMutation. */
export type BatchChangeSubEventParticipationStatusMutationPayload = {
  __typename?: 'BatchChangeSubEventParticipationStatusMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

export enum BatchChangeSubEventTargetStatus {
  Attended = 'ATTENDED'
}

/** Autogenerated input type of BatchCopyEmailsMutation */
export type BatchCopyEmailsMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  emailIds: Array<Scalars['ID']['input']>;
  eventId: Scalars['ID']['input'];
};

/** Autogenerated return type of BatchCopyEmailsMutation. */
export type BatchCopyEmailsMutationPayload = {
  __typename?: 'BatchCopyEmailsMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of BatchRemoveParticipantsFromGroupMutation */
export type BatchRemoveParticipantsFromGroupMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventGroupId: Scalars['ID']['input'];
  participationIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of BatchRemoveParticipantsFromGroupMutation. */
export type BatchRemoveParticipantsFromGroupMutationPayload = {
  __typename?: 'BatchRemoveParticipantsFromGroupMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of BatchRemoveParticipantsFromSubEventMutation */
export type BatchRemoveParticipantsFromSubEventMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  participationIds: Array<Scalars['ID']['input']>;
  subEventId: Scalars['ID']['input'];
};

/** Autogenerated return type of BatchRemoveParticipantsFromSubEventMutation. */
export type BatchRemoveParticipantsFromSubEventMutationPayload = {
  __typename?: 'BatchRemoveParticipantsFromSubEventMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of BatchRemoveParticipantsMutation */
export type BatchRemoveParticipantsMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  participationIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of BatchRemoveParticipantsMutation. */
export type BatchRemoveParticipantsMutationPayload = {
  __typename?: 'BatchRemoveParticipantsMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of BatchSendEmailMutation */
export type BatchSendEmailMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  customParameters?: InputMaybe<Scalars['JSON']['input']>;
  emailId: Scalars['ID']['input'];
  participationIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of BatchSendEmailMutation. */
export type BatchSendEmailMutationPayload = {
  __typename?: 'BatchSendEmailMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of BatchTagInstitution */
export type BatchTagInstitutionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  institutionIds: Array<Scalars['ID']['input']>;
  tags: Array<Scalars['String']['input']>;
};

/** Autogenerated return type of BatchTagInstitution. */
export type BatchTagInstitutionPayload = {
  __typename?: 'BatchTagInstitutionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of BatchUpdatePeopleSource */
export type BatchUpdatePeopleSourceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  peopleIds: Array<Scalars['ID']['input']>;
  source: Scalars['String']['input'];
};

/** Autogenerated return type of BatchUpdatePeopleSource. */
export type BatchUpdatePeopleSourcePayload = {
  __typename?: 'BatchUpdatePeopleSourcePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

export type Capacity = {
  __typename?: 'Capacity';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type CapacityCollection = {
  __typename?: 'CapacityCollection';
  collection: Array<Capacity>;
  export: CollectionExport;
  meta: CollectionMetadata;
};


export type CapacityCollectionExportArgs = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  format?: InputMaybe<CollectionExportFormat>;
};

export type ChangelogItem = {
  __typename?: 'ChangelogItem';
  canUndo: Scalars['Boolean']['output'];
  changes?: Maybe<Array<Scalars['String']['output']>>;
  changeset?: Maybe<Array<ChangelogItemChangeset>>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  event: ChangelogItemEventEnum;
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  who?: Maybe<User>;
};

export type ChangelogItemChangeset = {
  __typename?: 'ChangelogItemChangeset';
  field: Scalars['String']['output'];
  fieldLabel: Scalars['String']['output'];
  newValue?: Maybe<ChangelogItemChangesetValueUnion>;
  oldValue?: Maybe<ChangelogItemChangesetValueUnion>;
};

export type ChangelogItemChangesetReferenceValue = {
  __typename?: 'ChangelogItemChangesetReferenceValue';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type ChangelogItemChangesetSimpleValue = {
  __typename?: 'ChangelogItemChangesetSimpleValue';
  value?: Maybe<Scalars['String']['output']>;
};

export type ChangelogItemChangesetValueUnion = ChangelogItemChangesetReferenceValue | ChangelogItemChangesetSimpleValue;

export type ChangelogItemCollection = {
  __typename?: 'ChangelogItemCollection';
  collection: Array<ChangelogItem>;
  export: CollectionExport;
  meta: CollectionMetadata;
};


export type ChangelogItemCollectionExportArgs = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  format?: InputMaybe<CollectionExportFormat>;
};

export enum ChangelogItemEventEnum {
  Create = 'CREATE',
  Destroy = 'DESTROY',
  Update = 'UPDATE'
}

export enum ChangelogModelEnum {
  Event = 'EVENT',
  Institution = 'INSTITUTION',
  Person = 'PERSON',
  TagList = 'TAG_LIST'
}

export enum CheckInOut {
  Guaranteed = 'GUARANTEED',
  Requested = 'REQUESTED'
}

export type CollectionExport = {
  __typename?: 'CollectionExport';
  url?: Maybe<Scalars['String']['output']>;
};

export enum CollectionExportFormat {
  Csv = 'CSV',
  Xlsx = 'XLSX'
}

export type CollectionMetadata = {
  __typename?: 'CollectionMetadata';
  /** Items per page */
  items: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  pages: Scalars['Int']['output'];
  series: Array<Scalars['String']['output']>;
  total: Scalars['Int']['output'];
};

export type ContactKind = {
  __typename?: 'ContactKind';
  createdAt: Scalars['ISO8601DateTime']['output'];
  icon?: Maybe<ContactKindIcon>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type ContactKindCollection = {
  __typename?: 'ContactKindCollection';
  collection: Array<ContactKind>;
  export: CollectionExport;
  meta: CollectionMetadata;
};


export type ContactKindCollectionExportArgs = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  format?: InputMaybe<CollectionExportFormat>;
};

export enum ContactKindIcon {
  Email = 'EMAIL',
  Facebook = 'FACEBOOK',
  Fax = 'FAX',
  Im = 'IM',
  Mobile = 'MOBILE',
  Phone = 'PHONE',
  Skype = 'SKYPE',
  Twitter = 'TWITTER',
  Website = 'WEBSITE',
  Youtube = 'YOUTUBE'
}

export type ContactsFilters = {
  importance?: InputMaybe<ContactsFiltersImportance>;
  kind?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum ContactsFiltersImportance {
  All = 'ALL',
  NotPrimary = 'NOT_PRIMARY',
  Primary = 'PRIMARY'
}

/** Autogenerated input type of CopyFormMutation */
export type CopyFormMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['ID']['input'];
  formId: Scalars['ID']['input'];
};

/** Autogenerated return type of CopyFormMutation. */
export type CopyFormMutationPayload = {
  __typename?: 'CopyFormMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  form?: Maybe<Form>;
  success: Scalars['Boolean']['output'];
};

export type CostCenter = {
  __typename?: 'CostCenter';
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type CostCenterCollection = {
  __typename?: 'CostCenterCollection';
  collection: Array<CostCenter>;
  export: CollectionExport;
  meta: CollectionMetadata;
};


export type CostCenterCollectionExportArgs = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  format?: InputMaybe<CollectionExportFormat>;
};

export type CountriesFilters = {
  deskOfficerId?: InputMaybe<Scalars['ID']['input']>;
  havingCallingCodes?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CountriesSort = {
  direction?: InputMaybe<Direction>;
  field?: InputMaybe<CountriesSortFieldsType>;
};

export enum CountriesSortFieldsType {
  Name = 'NAME'
}

/** Geographic country */
export type Country = {
  __typename?: 'Country';
  callingCodes: Array<Scalars['String']['output']>;
  /** ISO 2 letter code */
  code: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  deskOfficer?: Maybe<Person>;
  id: Scalars['ID']['output'];
  jdCoordinator?: Maybe<Person>;
  name: Scalars['String']['output'];
  nationalExpert?: Maybe<Person>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type CountryCollection = {
  __typename?: 'CountryCollection';
  collection: Array<Country>;
  export: CollectionExport;
  meta: CollectionMetadata;
};


export type CountryCollectionExportArgs = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  format?: InputMaybe<CollectionExportFormat>;
};

export type CreateAccommodationDetails = {
  amountPaid?: InputMaybe<Scalars['String']['input']>;
  bookedOnline?: InputMaybe<Scalars['Boolean']['input']>;
  cancelled?: InputMaybe<Scalars['Boolean']['input']>;
  confirmation?: InputMaybe<Scalars['String']['input']>;
  dietaryRequirements?: InputMaybe<Scalars['String']['input']>;
  earlyCheckIn?: InputMaybe<Scalars['Boolean']['input']>;
  earlyCheckInTime?: InputMaybe<Scalars['String']['input']>;
  earlyCheckInType?: InputMaybe<CheckInOut>;
  endDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  endTime?: InputMaybe<Scalars['String']['input']>;
  extrasPaid?: InputMaybe<Scalars['Boolean']['input']>;
  kosher?: InputMaybe<Scalars['Boolean']['input']>;
  lateCheckOut?: InputMaybe<Scalars['Boolean']['input']>;
  lateCheckOutTime?: InputMaybe<Scalars['String']['input']>;
  lateCheckOutType?: InputMaybe<CheckInOut>;
  lodgeId?: InputMaybe<Scalars['ID']['input']>;
  lodgeRoomId?: InputMaybe<Scalars['ID']['input']>;
  loyaltyCardNumber?: InputMaybe<Scalars['String']['input']>;
  nightsPaid?: InputMaybe<Scalars['String']['input']>;
  paidStatus?: InputMaybe<PaidStatus>;
  pricePerNightAmount?: InputMaybe<Scalars['Float']['input']>;
  pricePerNightCurrency?: InputMaybe<Currency>;
  roomCode?: InputMaybe<Scalars['String']['input']>;
  roomNumber?: InputMaybe<Scalars['String']['input']>;
  roomRequirements?: InputMaybe<Scalars['String']['input']>;
  sharedWithId?: InputMaybe<Scalars['ID']['input']>;
  smoking?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of CreateAffiliationMutation */
export type CreateAffiliationMutationInput = {
  affiliationTypeId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  institutionId: Scalars['ID']['input'];
  relatedInstitutionId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateAffiliationMutation. */
export type CreateAffiliationMutationPayload = {
  __typename?: 'CreateAffiliationMutationPayload';
  affiliation?: Maybe<Affiliation>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateAffiliationTypeMutation */
export type CreateAffiliationTypeMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  wjcSpecific?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of CreateAffiliationTypeMutation. */
export type CreateAffiliationTypeMutationPayload = {
  __typename?: 'CreateAffiliationTypeMutationPayload';
  affiliationType?: Maybe<AffiliationType>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateCapacityMutation */
export type CreateCapacityMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Autogenerated return type of CreateCapacityMutation. */
export type CreateCapacityMutationPayload = {
  __typename?: 'CreateCapacityMutationPayload';
  capacity?: Maybe<Capacity>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateContactKindMutation */
export type CreateContactKindMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Autogenerated return type of CreateContactKindMutation. */
export type CreateContactKindMutationPayload = {
  __typename?: 'CreateContactKindMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  contactKind?: Maybe<ContactKind>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateCostCenterMutation */
export type CreateCostCenterMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Autogenerated return type of CreateCostCenterMutation. */
export type CreateCostCenterMutationPayload = {
  __typename?: 'CreateCostCenterMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  costCenter?: Maybe<CostCenter>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

export type CreateEmailDetails = {
  name: Scalars['String']['input'];
};

/** Autogenerated input type of CreateEmailMutation */
export type CreateEmailMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: CreateEmailDetails;
  eventId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateEmailMutation. */
export type CreateEmailMutationPayload = {
  __typename?: 'CreateEmailMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Email>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

export type CreateEventDetails = {
  confirmationTag?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  enableAccommodations?: InputMaybe<Scalars['Boolean']['input']>;
  enableCapacities?: InputMaybe<Scalars['Boolean']['input']>;
  enableGroups?: InputMaybe<Scalars['Boolean']['input']>;
  enablePassports?: InputMaybe<Scalars['Boolean']['input']>;
  enableTables?: InputMaybe<Scalars['Boolean']['input']>;
  enableTransportLocations?: InputMaybe<Scalars['Boolean']['input']>;
  enableVoting?: InputMaybe<Scalars['Boolean']['input']>;
  endDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  endTime?: InputMaybe<Scalars['String']['input']>;
  eventTypeId?: InputMaybe<Scalars['ID']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  officialName?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
  wjcEvent?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of CreateEventGroupMutation */
export type CreateEventGroupMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: EventGroupDetails;
  eventId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateEventGroupMutation. */
export type CreateEventGroupMutationPayload = {
  __typename?: 'CreateEventGroupMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  eventGroup?: Maybe<EventGroup>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateEventGroupTypeMutation */
export type CreateEventGroupTypeMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Autogenerated return type of CreateEventGroupTypeMutation. */
export type CreateEventGroupTypeMutationPayload = {
  __typename?: 'CreateEventGroupTypeMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  eventGroupType?: Maybe<EventGroupType>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateEventLodge */
export type CreateEventLodgeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: LodgeDetails;
  eventId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateEventLodge. */
export type CreateEventLodgePayload = {
  __typename?: 'CreateEventLodgePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  lodge?: Maybe<Lodge>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateEventLodgeRoom */
export type CreateEventLodgeRoomInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: LodgeRoomDetails;
  lodgeId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateEventLodgeRoom. */
export type CreateEventLodgeRoomPayload = {
  __typename?: 'CreateEventLodgeRoomPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  room?: Maybe<LodgeRoomQuantity>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateEventMutation */
export type CreateEventMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: CreateEventDetails;
};

/** Autogenerated return type of CreateEventMutation. */
export type CreateEventMutationPayload = {
  __typename?: 'CreateEventMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  event?: Maybe<Event>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateEventParticipationMutation */
export type CreateEventParticipationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['ID']['input'];
  personId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateEventParticipationMutation. */
export type CreateEventParticipationMutationPayload = {
  __typename?: 'CreateEventParticipationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  eventParticipation?: Maybe<EventParticipation>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateEventTypeMutation */
export type CreateEventTypeMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Autogenerated return type of CreateEventTypeMutation. */
export type CreateEventTypeMutationPayload = {
  __typename?: 'CreateEventTypeMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  eventType?: Maybe<EventType>;
  success: Scalars['Boolean']['output'];
};

export type CreateFormDetails = {
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

/** Autogenerated input type of CreateFormMutation */
export type CreateFormMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: CreateFormDetails;
  eventId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateFormMutation. */
export type CreateFormMutationPayload = {
  __typename?: 'CreateFormMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  form?: Maybe<Form>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateInstitutionContact */
export type CreateInstitutionContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details?: InstitutionContactInput;
  institutionId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateInstitutionContact. */
export type CreateInstitutionContactPayload = {
  __typename?: 'CreateInstitutionContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<InstitutionContact>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

export type CreateInstitutionInput = {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  globalContact?: InputMaybe<InstitutionGlobalContact>;
  inheritDeskOfficer?: InputMaybe<Scalars['Boolean']['input']>;
  isInternationalOrganization?: InputMaybe<Scalars['Boolean']['input']>;
  languages?: InputMaybe<Array<Scalars['String']['input']>>;
  locationEstablished?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  officialName?: InputMaybe<Scalars['String']['input']>;
  pointPersonId?: InputMaybe<Scalars['ID']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  yearEstablished?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of CreateInstitutionLocationContact */
export type CreateInstitutionLocationContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details?: InstitutionLocationContactInput;
  institutionLocationId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateInstitutionLocationContact. */
export type CreateInstitutionLocationContactPayload = {
  __typename?: 'CreateInstitutionLocationContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<InstitutionLocationContact>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateInstitutionLocationMutation */
export type CreateInstitutionLocationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  institutionId: Scalars['ID']['input'];
  locationFields: InstitutionLocationInput;
};

/** Autogenerated return type of CreateInstitutionLocationMutation. */
export type CreateInstitutionLocationMutationPayload = {
  __typename?: 'CreateInstitutionLocationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  institutionLocation?: Maybe<InstitutionLocation>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateInstitutionMembership */
export type CreateInstitutionMembershipInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: InstitutionMembershipInput;
  institutionId: Scalars['ID']['input'];
  personId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateInstitutionMembership. */
export type CreateInstitutionMembershipPayload = {
  __typename?: 'CreateInstitutionMembershipPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  institutionMembership?: Maybe<InstitutionMembership>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateInstitutionMutation */
export type CreateInstitutionMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: CreateInstitutionInput;
};

/** Autogenerated return type of CreateInstitutionMutation. */
export type CreateInstitutionMutationPayload = {
  __typename?: 'CreateInstitutionMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  institution?: Maybe<Institution>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateMembershipContact */
export type CreateMembershipContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details?: MembershipContactInput;
  institutionMembershipId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateMembershipContact. */
export type CreateMembershipContactPayload = {
  __typename?: 'CreateMembershipContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<InstitutionMembershipContact>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateMembershipPosition */
export type CreateMembershipPositionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details?: MembershipPositionInput;
  institutionMembershipId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateMembershipPosition. */
export type CreateMembershipPositionPayload = {
  __typename?: 'CreateMembershipPositionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  position?: Maybe<InstitutionMembershipPosition>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateMutation */
export type CreateMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: CreateAccommodationDetails;
  participationId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateMutation. */
export type CreateMutationPayload = {
  __typename?: 'CreateMutationPayload';
  accommodation?: Maybe<Accommodation>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreatePassportMutation */
export type CreatePassportMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  passportFields: PersonPassportInput;
  personId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreatePassportMutation. */
export type CreatePassportMutationPayload = {
  __typename?: 'CreatePassportMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  passport?: Maybe<Passport>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreatePeopleRelationshipTypeMutation */
export type CreatePeopleRelationshipTypeMutationInput = {
  category: RelationshipTypeEnum;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  opposite?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreatePeopleRelationshipTypeMutation. */
export type CreatePeopleRelationshipTypeMutationPayload = {
  __typename?: 'CreatePeopleRelationshipTypeMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  relationshipType?: Maybe<RelationshipType>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreatePersonContact */
export type CreatePersonContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details?: PersonContactInput;
  personId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreatePersonContact. */
export type CreatePersonContactPayload = {
  __typename?: 'CreatePersonContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<PersonContact>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

export type CreatePersonInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
  namesDisplayOrder: NamesDisplayOrder;
  titleId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated input type of CreatePersonLocationContact */
export type CreatePersonLocationContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details?: PersonLocationContactInput;
  personLocationId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreatePersonLocationContact. */
export type CreatePersonLocationContactPayload = {
  __typename?: 'CreatePersonLocationContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<PersonLocationContact>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreatePersonLocationMutation */
export type CreatePersonLocationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  locationFields: PersonLocationInput;
  personId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreatePersonLocationMutation. */
export type CreatePersonLocationMutationPayload = {
  __typename?: 'CreatePersonLocationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  personLocation?: Maybe<PersonLocation>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreatePersonMutation */
export type CreatePersonMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: CreatePersonInput;
};

/** Autogenerated return type of CreatePersonMutation. */
export type CreatePersonMutationPayload = {
  __typename?: 'CreatePersonMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  person?: Maybe<Person>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreatePersonPersonalEmailMutation */
export type CreatePersonPersonalEmailMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: CreatePersonWithEmailInput;
};

/** Autogenerated return type of CreatePersonPersonalEmailMutation. */
export type CreatePersonPersonalEmailMutationPayload = {
  __typename?: 'CreatePersonPersonalEmailMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  person?: Maybe<Person>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreatePersonProfessionalEmailMutation */
export type CreatePersonProfessionalEmailMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: CreatePersonWithProfessionalEmailInput;
  institutionId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreatePersonProfessionalEmailMutation. */
export type CreatePersonProfessionalEmailMutationPayload = {
  __typename?: 'CreatePersonProfessionalEmailMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  person?: Maybe<Person>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreatePersonRelationshipMutation */
export type CreatePersonRelationshipMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  personId: Scalars['ID']['input'];
  relatedPersonId: Scalars['ID']['input'];
  relationshipTypeId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreatePersonRelationshipMutation. */
export type CreatePersonRelationshipMutationPayload = {
  __typename?: 'CreatePersonRelationshipMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  relationship?: Maybe<Relationship>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreatePersonTitleMutation */
export type CreatePersonTitleMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

/** Autogenerated return type of CreatePersonTitleMutation. */
export type CreatePersonTitleMutationPayload = {
  __typename?: 'CreatePersonTitleMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  personTitle?: Maybe<PersonTitle>;
  success: Scalars['Boolean']['output'];
};

export type CreatePersonWithEmailInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
  namesDisplayOrder: NamesDisplayOrder;
  personalContact?: InputMaybe<PersonalContactInput>;
  titleId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreatePersonWithProfessionalEmailInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
  namesDisplayOrder: NamesDisplayOrder;
  professionalContact?: InputMaybe<ProfessionalContactInput>;
  titleId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreatePresetDetails = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<PresetSortInput>;
};

/** Autogenerated input type of CreatePresetMutation */
export type CreatePresetMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: CreatePresetDetails;
  eventId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreatePresetMutation. */
export type CreatePresetMutationPayload = {
  __typename?: 'CreatePresetMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  eventPreset?: Maybe<EventPreset>;
  success: Scalars['Boolean']['output'];
};

export type CreateReleaseNoteDetails = {
  date: Scalars['ISO8601Date']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of CreateReleaseNoteMutation */
export type CreateReleaseNoteMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: CreateReleaseNoteDetails;
};

/** Autogenerated return type of CreateReleaseNoteMutation. */
export type CreateReleaseNoteMutationPayload = {
  __typename?: 'CreateReleaseNoteMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  releaseNote?: Maybe<ReleaseNote>;
  success: Scalars['Boolean']['output'];
};

export type CreateRoomDetails = {
  landscape?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of CreateRoomMutation */
export type CreateRoomMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: CreateRoomDetails;
  subEventId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateRoomMutation. */
export type CreateRoomMutationPayload = {
  __typename?: 'CreateRoomMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  room?: Maybe<Room>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateRoomTypeMutation */
export type CreateRoomTypeMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Autogenerated return type of CreateRoomTypeMutation. */
export type CreateRoomTypeMutationPayload = {
  __typename?: 'CreateRoomTypeMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  roomType?: Maybe<RoomType>;
  success: Scalars['Boolean']['output'];
};

export type CreateSubEventDetails = {
  endDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  endTime?: InputMaybe<Scalars['String']['input']>;
  inheritParticipants?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of CreateSubEventMutation */
export type CreateSubEventMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: CreateSubEventDetails;
  eventId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateSubEventMutation. */
export type CreateSubEventMutationPayload = {
  __typename?: 'CreateSubEventMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  subEvent?: Maybe<SubEvent>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateSubEventParticipationMutation */
export type CreateSubEventParticipationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  participationId: Scalars['ID']['input'];
  subEventId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateSubEventParticipationMutation. */
export type CreateSubEventParticipationMutationPayload = {
  __typename?: 'CreateSubEventParticipationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  subEventParticipation?: Maybe<SubEventParticipation>;
  success: Scalars['Boolean']['output'];
};

export type CreateTableDetails = {
  kind: Scalars['String']['input'];
  left: Scalars['Int']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  number: Scalars['Int']['input'];
  rotation: Scalars['Int']['input'];
  seatsCount1: Scalars['Int']['input'];
  seatsCount2?: InputMaybe<Scalars['Int']['input']>;
  seatsCount3?: InputMaybe<Scalars['Int']['input']>;
  seatsCount4?: InputMaybe<Scalars['Int']['input']>;
  top: Scalars['Int']['input'];
};

/** Autogenerated input type of CreateTableMutation */
export type CreateTableMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: CreateTableDetails;
  roomId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateTableMutation. */
export type CreateTableMutationPayload = {
  __typename?: 'CreateTableMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
  table?: Maybe<Table>;
};

/** Autogenerated input type of CreateTagListMutation */
export type CreateTagListMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  condition: TagListCondition;
  description?: InputMaybe<Scalars['String']['input']>;
  kind: TagListKind;
  name: Scalars['String']['input'];
  pointPersonId?: InputMaybe<Scalars['ID']['input']>;
  tags: Array<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateTagListMutation. */
export type CreateTagListMutationPayload = {
  __typename?: 'CreateTagListMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
  tagList?: Maybe<TagList>;
};

/** Autogenerated input type of CreateTagMutation */
export type CreateTagMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Autogenerated return type of CreateTagMutation. */
export type CreateTagMutationPayload = {
  __typename?: 'CreateTagMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
  tag?: Maybe<Tag>;
};

/** Autogenerated input type of CreateTransportLocationMutation */
export type CreateTransportLocationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

/** Autogenerated return type of CreateTransportLocationMutation. */
export type CreateTransportLocationMutationPayload = {
  __typename?: 'CreateTransportLocationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  eventTransportLocation?: Maybe<EventTransportLocation>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateVotingGroupMutation */
export type CreateVotingGroupMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

/** Autogenerated return type of CreateVotingGroupMutation. */
export type CreateVotingGroupMutationPayload = {
  __typename?: 'CreateVotingGroupMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
  votingGroup?: Maybe<VotingGroup>;
};

export type CreateVotingKeyDetails = {
  name?: InputMaybe<Scalars['String']['input']>;
  slots?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of CreateVotingKeyMutation */
export type CreateVotingKeyMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: CreateVotingKeyDetails;
  votingGroupId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateVotingKeyMutation. */
export type CreateVotingKeyMutationPayload = {
  __typename?: 'CreateVotingKeyMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
  votingKey?: Maybe<VotingKey>;
};

export enum CreditCardType {
  AmericanExpress = 'AMERICAN_EXPRESS',
  Dinersclub = 'DINERSCLUB',
  Mastercard = 'MASTERCARD',
  Visa = 'VISA'
}

export enum Currency {
  Eur = 'EUR',
  Gbp = 'GBP',
  Ils = 'ILS',
  Usd = 'USD'
}

/** Autogenerated input type of DeclineFormSubmissionMutation */
export type DeclineFormSubmissionMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  formSubmissionId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeclineFormSubmissionMutation. */
export type DeclineFormSubmissionMutationPayload = {
  __typename?: 'DeclineFormSubmissionMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  formSubmission?: Maybe<FormSubmission>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteAffiliationMutation */
export type DeleteAffiliationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteAffiliationMutation. */
export type DeleteAffiliationMutationPayload = {
  __typename?: 'DeleteAffiliationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteAffiliationTypeMutation */
export type DeleteAffiliationTypeMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of DeleteAffiliationTypeMutation. */
export type DeleteAffiliationTypeMutationPayload = {
  __typename?: 'DeleteAffiliationTypeMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteCapacityMutation */
export type DeleteCapacityMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of DeleteCapacityMutation. */
export type DeleteCapacityMutationPayload = {
  __typename?: 'DeleteCapacityMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteContactKindMutation */
export type DeleteContactKindMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the room type */
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of DeleteContactKindMutation. */
export type DeleteContactKindMutationPayload = {
  __typename?: 'DeleteContactKindMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteCostCenterMutation */
export type DeleteCostCenterMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the cost center */
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of DeleteCostCenterMutation. */
export type DeleteCostCenterMutationPayload = {
  __typename?: 'DeleteCostCenterMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteEmailMutation */
export type DeleteEmailMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  emailId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteEmailMutation. */
export type DeleteEmailMutationPayload = {
  __typename?: 'DeleteEmailMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteEventGroupMutation */
export type DeleteEventGroupMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventGroupId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteEventGroupMutation. */
export type DeleteEventGroupMutationPayload = {
  __typename?: 'DeleteEventGroupMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteEventGroupTypeMutation */
export type DeleteEventGroupTypeMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteEventGroupTypeMutation. */
export type DeleteEventGroupTypeMutationPayload = {
  __typename?: 'DeleteEventGroupTypeMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteEventLodge */
export type DeleteEventLodgeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  lodgeId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteEventLodge. */
export type DeleteEventLodgePayload = {
  __typename?: 'DeleteEventLodgePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteEventLodgeRoom */
export type DeleteEventLodgeRoomInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  roomId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteEventLodgeRoom. */
export type DeleteEventLodgeRoomPayload = {
  __typename?: 'DeleteEventLodgeRoomPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteEventTypeMutation */
export type DeleteEventTypeMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteEventTypeMutation. */
export type DeleteEventTypeMutationPayload = {
  __typename?: 'DeleteEventTypeMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteInstitutionContact */
export type DeleteInstitutionContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contactId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteInstitutionContact. */
export type DeleteInstitutionContactPayload = {
  __typename?: 'DeleteInstitutionContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteInstitutionLocationContact */
export type DeleteInstitutionLocationContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contactId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteInstitutionLocationContact. */
export type DeleteInstitutionLocationContactPayload = {
  __typename?: 'DeleteInstitutionLocationContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteInstitutionLocationMutation */
export type DeleteInstitutionLocationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  institutionLocationId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteInstitutionLocationMutation. */
export type DeleteInstitutionLocationMutationPayload = {
  __typename?: 'DeleteInstitutionLocationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteInstitutionMembership */
export type DeleteInstitutionMembershipInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  institutionMembershipId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteInstitutionMembership. */
export type DeleteInstitutionMembershipPayload = {
  __typename?: 'DeleteInstitutionMembershipPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  institutionMembership?: Maybe<InstitutionMembership>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteMembershipContact */
export type DeleteMembershipContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contactId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteMembershipContact. */
export type DeleteMembershipContactPayload = {
  __typename?: 'DeleteMembershipContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteMembershipPosition */
export type DeleteMembershipPositionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  positionId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteMembershipPosition. */
export type DeleteMembershipPositionPayload = {
  __typename?: 'DeleteMembershipPositionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteMutation */
export type DeleteMutationInput = {
  accommodationId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DeleteMutation. */
export type DeleteMutationPayload = {
  __typename?: 'DeleteMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteParticipationMutation */
export type DeleteParticipationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  participationId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteParticipationMutation. */
export type DeleteParticipationMutationPayload = {
  __typename?: 'DeleteParticipationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeletePassportMutation */
export type DeletePassportMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  passportId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeletePassportMutation. */
export type DeletePassportMutationPayload = {
  __typename?: 'DeletePassportMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeletePeopleRelationshipTypeMutation */
export type DeletePeopleRelationshipTypeMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the relationship type */
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of DeletePeopleRelationshipTypeMutation. */
export type DeletePeopleRelationshipTypeMutationPayload = {
  __typename?: 'DeletePeopleRelationshipTypeMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeletePersonContact */
export type DeletePersonContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contactId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeletePersonContact. */
export type DeletePersonContactPayload = {
  __typename?: 'DeletePersonContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeletePersonLocationContact */
export type DeletePersonLocationContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contactId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeletePersonLocationContact. */
export type DeletePersonLocationContactPayload = {
  __typename?: 'DeletePersonLocationContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeletePersonLocationMutation */
export type DeletePersonLocationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  personLocationId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeletePersonLocationMutation. */
export type DeletePersonLocationMutationPayload = {
  __typename?: 'DeletePersonLocationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeletePersonRelationshipMutation */
export type DeletePersonRelationshipMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeletePersonRelationshipMutation. */
export type DeletePersonRelationshipMutationPayload = {
  __typename?: 'DeletePersonRelationshipMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeletePersonTitleMutation */
export type DeletePersonTitleMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeletePersonTitleMutation. */
export type DeletePersonTitleMutationPayload = {
  __typename?: 'DeletePersonTitleMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeletePresetMutation */
export type DeletePresetMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  presetId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeletePresetMutation. */
export type DeletePresetMutationPayload = {
  __typename?: 'DeletePresetMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteReleaseNoteMutation */
export type DeleteReleaseNoteMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  releaseNoteId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteReleaseNoteMutation. */
export type DeleteReleaseNoteMutationPayload = {
  __typename?: 'DeleteReleaseNoteMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteRoomMutation */
export type DeleteRoomMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  roomId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteRoomMutation. */
export type DeleteRoomMutationPayload = {
  __typename?: 'DeleteRoomMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteRoomTypeMutation */
export type DeleteRoomTypeMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the room type */
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of DeleteRoomTypeMutation. */
export type DeleteRoomTypeMutationPayload = {
  __typename?: 'DeleteRoomTypeMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteSubEventMutation */
export type DeleteSubEventMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  subEventId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteSubEventMutation. */
export type DeleteSubEventMutationPayload = {
  __typename?: 'DeleteSubEventMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteSubEventParticipationMutation */
export type DeleteSubEventParticipationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  subEventParticipationId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteSubEventParticipationMutation. */
export type DeleteSubEventParticipationMutationPayload = {
  __typename?: 'DeleteSubEventParticipationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteTableMutation */
export type DeleteTableMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  tableId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteTableMutation. */
export type DeleteTableMutationPayload = {
  __typename?: 'DeleteTableMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteTagListMutation */
export type DeleteTagListMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteTagListMutation. */
export type DeleteTagListMutationPayload = {
  __typename?: 'DeleteTagListMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteTagMutation */
export type DeleteTagMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteTagMutation. */
export type DeleteTagMutationPayload = {
  __typename?: 'DeleteTagMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteTransportLocationMutation */
export type DeleteTransportLocationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventTransportLocationId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteTransportLocationMutation. */
export type DeleteTransportLocationMutationPayload = {
  __typename?: 'DeleteTransportLocationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteVotingGroupMutation */
export type DeleteVotingGroupMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  votingGroupId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteVotingGroupMutation. */
export type DeleteVotingGroupMutationPayload = {
  __typename?: 'DeleteVotingGroupMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteVotingKeyMutation */
export type DeleteVotingKeyMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  votingKeyId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteVotingKeyMutation. */
export type DeleteVotingKeyMutationPayload = {
  __typename?: 'DeleteVotingKeyMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

export enum Direction {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Email = {
  __typename?: 'Email';
  body: Scalars['String']['output'];
  conditions: Array<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  event: Event;
  id: Scalars['ID']['output'];
  kind: EmailKind;
  mergeTags: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  parsedBody: Scalars['String']['output'];
  subject: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum EmailKind {
  AccommodationConfirmation = 'accommodation_confirmation',
  Approval = 'approval',
  Confirmation = 'confirmation',
  Custom = 'custom',
  Rejection = 'rejection'
}

export type EmailPreview = {
  __typename?: 'EmailPreview';
  bcc?: Maybe<Array<Scalars['String']['output']>>;
  body?: Maybe<Scalars['String']['output']>;
  cc?: Maybe<Array<Scalars['String']['output']>>;
  subject?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Array<Scalars['String']['output']>>;
};

export type EmailSentToParticipant = {
  __typename?: 'EmailSentToParticipant';
  body: Scalars['String']['output'];
  cc: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  kind: EmailKind;
  sentAt: Scalars['ISO8601DateTime']['output'];
  subject: Scalars['String']['output'];
  to: Array<Scalars['String']['output']>;
};

/** Base type for all mutations errors */
export type Error = {
  __typename?: 'Error';
  messages: Array<Scalars['String']['output']>;
  path: Scalars['String']['output'];
};

export type Event = {
  __typename?: 'Event';
  capacities: Array<EventCapacity>;
  confirmationTag?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  enableAccommodations: Scalars['Boolean']['output'];
  enableCapacities: Scalars['Boolean']['output'];
  enableGroups: Scalars['Boolean']['output'];
  enablePassports: Scalars['Boolean']['output'];
  enableTables: Scalars['Boolean']['output'];
  enableTransportLocations: Scalars['Boolean']['output'];
  enableVoting: Scalars['Boolean']['output'];
  endDate?: Maybe<Scalars['ISO8601Date']['output']>;
  endTime?: Maybe<Scalars['String']['output']>;
  eventPresets: Array<EventPreset>;
  eventType: EventType;
  groups: Array<EventGroup>;
  id: Scalars['ID']['output'];
  isArchived: Scalars['Boolean']['output'];
  location?: Maybe<Scalars['String']['output']>;
  lodges: Array<Lodge>;
  name: Scalars['String']['output'];
  officialName: Scalars['String']['output'];
  representedInstitutions: Array<Institution>;
  startDate?: Maybe<Scalars['ISO8601Date']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
  subEvents: Array<SubEvent>;
  transportLocations: Array<EventTransportLocation>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  votingGroups: Array<VotingGroup>;
  wjcEvent: Scalars['Boolean']['output'];
};

export type EventCapacity = {
  __typename?: 'EventCapacity';
  capacity: Capacity;
  createdAt: Scalars['ISO8601DateTime']['output'];
  event: Event;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type EventCollection = {
  __typename?: 'EventCollection';
  collection: Array<Event>;
  export: CollectionExport;
  meta: CollectionMetadata;
};


export type EventCollectionExportArgs = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  format?: InputMaybe<CollectionExportFormat>;
};

/** EventGroup */
export type EventGroup = {
  __typename?: 'EventGroup';
  createdAt: Scalars['ISO8601DateTime']['output'];
  event: Event;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: EventGroupType;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type EventGroupDetails = {
  eventGroupTypeId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type EventGroupType = {
  __typename?: 'EventGroupType';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type EventGroupTypeCollection = {
  __typename?: 'EventGroupTypeCollection';
  collection: Array<EventGroupType>;
  export: CollectionExport;
  meta: CollectionMetadata;
};


export type EventGroupTypeCollectionExportArgs = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  format?: InputMaybe<CollectionExportFormat>;
};

export type EventParticipantsFilters = {
  canShareAccommodationId?: InputMaybe<Scalars['ID']['input']>;
  capacityIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  groupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  lodgeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  notInGroupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  notReceivedEmailIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  receivedEmailIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  repliedVia?: InputMaybe<Array<ReplyChannels>>;
  representedInstitutionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  status?: InputMaybe<EventParticipationStatus>;
  subEventIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  transportLocationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type EventParticipation = {
  __typename?: 'EventParticipation';
  accommodations: Array<Accommodation>;
  approvalComment?: Maybe<Scalars['String']['output']>;
  approvalEmailSentAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  approved: Scalars['Boolean']['output'];
  approvedTravelAmount?: Maybe<Scalars['String']['output']>;
  arrivalTransport?: Maybe<EventTransport>;
  comment?: Maybe<Scalars['String']['output']>;
  contacts: Array<EventParticipationContact>;
  costCenter?: Maybe<CostCenter>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  customFieldOne?: Maybe<Scalars['String']['output']>;
  customFieldThree?: Maybe<Scalars['String']['output']>;
  customFieldTwo?: Maybe<Scalars['String']['output']>;
  departureTransport?: Maybe<EventTransport>;
  emailAddresses: Array<Scalars['String']['output']>;
  event: Event;
  eventCapacity?: Maybe<EventCapacity>;
  eventGroups: Array<EventGroup>;
  id: Scalars['ID']['output'];
  invitedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  mobileNumbers: Array<Scalars['String']['output']>;
  passport?: Maybe<Passport>;
  person: Person;
  repliedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  repliedVia?: Maybe<ReplyChannels>;
  replyDetails?: Maybe<Scalars['String']['output']>;
  representedBody?: Maybe<Scalars['String']['output']>;
  representedCountry?: Maybe<Country>;
  representedInstitutions: Array<Institution>;
  representedInstitutionsMemberships: Array<InstitutionMembership>;
  sentEmails: Array<EmailSentToParticipant>;
  sharedAccommodations: Array<Accommodation>;
  status?: Maybe<Scalars['String']['output']>;
  subEventParticipations: Array<SubEventParticipation>;
  travelPaid: Scalars['Boolean']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  votingKey?: Maybe<VotingKey>;
};

export type EventParticipationCollection = {
  __typename?: 'EventParticipationCollection';
  collection: Array<EventParticipation>;
  export: CollectionExport;
  meta: CollectionMetadata;
};


export type EventParticipationCollectionExportArgs = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  format?: InputMaybe<CollectionExportFormat>;
};

export type EventParticipationContact = {
  __typename?: 'EventParticipationContact';
  carbonCopy: Scalars['Boolean']['output'];
  contactId: Scalars['ID']['output'];
  direct: Scalars['Boolean']['output'];
};

export type EventParticipationDetailsInput = {
  approvalComment?: InputMaybe<Scalars['String']['input']>;
  approved?: InputMaybe<Scalars['Boolean']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  costCenterId?: InputMaybe<Scalars['ID']['input']>;
  customFieldOne?: InputMaybe<Scalars['String']['input']>;
  customFieldThree?: InputMaybe<Scalars['String']['input']>;
  customFieldTwo?: InputMaybe<Scalars['String']['input']>;
  invitedAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  passportId?: InputMaybe<Scalars['ID']['input']>;
  repliedAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  repliedVia?: InputMaybe<ReplyChannels>;
  representedBody?: InputMaybe<Scalars['String']['input']>;
  representedCountryId?: InputMaybe<Scalars['ID']['input']>;
  representedInstitutionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type EventParticipationForList = {
  __typename?: 'EventParticipationForList';
  accommodations?: Maybe<Array<EventParticipationForListAccommodation>>;
  arrival?: Maybe<EventParticipationForListArrival>;
  assistant?: Maybe<EventParticipationForListAssistant>;
  departure?: Maybe<EventParticipationForListDeparture>;
  event: Event;
  eventParticipation: EventParticipationForListParticipation;
  id: Scalars['ID']['output'];
  membership?: Maybe<EventParticipationForListMembership>;
  original: EventParticipation;
  passport?: Maybe<EventParticipationForListPassport>;
  person: EventParticipationForListPerson;
  spouse?: Maybe<EventParticipationForListSpouse>;
};

export type EventParticipationForListAccommodation = {
  __typename?: 'EventParticipationForListAccommodation';
  amountPaid?: Maybe<Scalars['String']['output']>;
  bookedOnline: Scalars['Boolean']['output'];
  cancelled: Scalars['Boolean']['output'];
  confirmation?: Maybe<Scalars['String']['output']>;
  dietaryRequirements?: Maybe<Scalars['String']['output']>;
  earlyCheckIn: Scalars['Boolean']['output'];
  earlyCheckInTime?: Maybe<Scalars['String']['output']>;
  earlyCheckInType?: Maybe<CheckInOut>;
  endDate: Scalars['ISO8601Date']['output'];
  endTime?: Maybe<Scalars['String']['output']>;
  extrasPaid: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  kosher: Scalars['Boolean']['output'];
  lateCheckOut: Scalars['Boolean']['output'];
  lateCheckOutTime?: Maybe<Scalars['String']['output']>;
  lateCheckOutType?: Maybe<CheckInOut>;
  lodge?: Maybe<Scalars['String']['output']>;
  loyaltyCardNumber?: Maybe<Scalars['String']['output']>;
  nights?: Maybe<Scalars['Int']['output']>;
  nightsPaid?: Maybe<Scalars['String']['output']>;
  paidStatus: PaidStatus;
  pricePerNightAmount?: Maybe<Scalars['Float']['output']>;
  pricePerNightCurrency?: Maybe<Currency>;
  roomCode?: Maybe<Scalars['String']['output']>;
  roomNumber?: Maybe<Scalars['String']['output']>;
  roomRequirements?: Maybe<Scalars['String']['output']>;
  roomType?: Maybe<Scalars['String']['output']>;
  sharedWith?: Maybe<Scalars['String']['output']>;
  smoking: Scalars['Boolean']['output'];
  startDate: Scalars['ISO8601Date']['output'];
  startTime?: Maybe<Scalars['String']['output']>;
};

export type EventParticipationForListArrival = {
  __typename?: 'EventParticipationForListArrival';
  comments?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['ISO8601Date']['output']>;
  id: Scalars['ID']['output'];
  location?: Maybe<Scalars['String']['output']>;
  pickup?: Maybe<Scalars['Boolean']['output']>;
  time?: Maybe<Scalars['String']['output']>;
  vip: Scalars['Boolean']['output'];
};

export type EventParticipationForListAssistant = {
  __typename?: 'EventParticipationForListAssistant';
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  group?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  title?: Maybe<PersonTitle>;
};

export type EventParticipationForListCollection = {
  __typename?: 'EventParticipationForListCollection';
  collection: Array<EventParticipationForList>;
  export: CollectionExport;
  meta: CollectionMetadata;
};


export type EventParticipationForListCollectionExportArgs = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  format?: InputMaybe<CollectionExportFormat>;
};

export type EventParticipationForListDeparture = {
  __typename?: 'EventParticipationForListDeparture';
  comments?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['ISO8601Date']['output']>;
  id: Scalars['ID']['output'];
  location?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['String']['output']>;
  transfer?: Maybe<Scalars['Boolean']['output']>;
  vip: Scalars['Boolean']['output'];
};

export type EventParticipationForListMembership = {
  __typename?: 'EventParticipationForListMembership';
  address1?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Country>;
  department?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  institution: Institution;
  position?: Maybe<Scalars['String']['output']>;
  secondaryPosition?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type EventParticipationForListParticipation = {
  __typename?: 'EventParticipationForListParticipation';
  accommodationsUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  approvalComment?: Maybe<Scalars['String']['output']>;
  approved: Scalars['Boolean']['output'];
  approvedTravelAmount?: Maybe<Scalars['String']['output']>;
  attended: Scalars['Boolean']['output'];
  cancelled: Scalars['Boolean']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  costCenter?: Maybe<CostCenter>;
  customFieldOne?: Maybe<Scalars['String']['output']>;
  customFieldThree?: Maybe<Scalars['String']['output']>;
  customFieldTwo?: Maybe<Scalars['String']['output']>;
  emails: Array<Scalars['String']['output']>;
  eventCapacity?: Maybe<EventCapacity>;
  eventGroups?: Maybe<Array<EventGroup>>;
  id: Scalars['ID']['output'];
  invited: Scalars['Boolean']['output'];
  invitedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  phones: Array<Scalars['String']['output']>;
  repliedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  repliedVia?: Maybe<Scalars['String']['output']>;
  representedBody?: Maybe<Scalars['String']['output']>;
  representedCountry?: Maybe<Country>;
  representedInstitutions: Array<Institution>;
  representedInstitutionsMemberships: Array<InstitutionMembership>;
  response?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  subEvents: Array<SubEvent>;
  transportUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  travelPaid: Scalars['Boolean']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  votingGroup?: Maybe<VotingKey>;
  votingKey?: Maybe<VotingGroup>;
};

export type EventParticipationForListPassport = {
  __typename?: 'EventParticipationForListPassport';
  birthday?: Maybe<Scalars['ISO8601Date']['output']>;
  citizenship?: Maybe<Country>;
  expiresAt?: Maybe<Scalars['ISO8601Date']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  issuedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
};

export type EventParticipationForListPerson = {
  __typename?: 'EventParticipationForListPerson';
  alternativeTitle?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Country>;
  deskOfficer?: Maybe<Person>;
  displayName: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  gender?: Maybe<Gender>;
  id: Scalars['ID']['output'];
  initials: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  middleName?: Maybe<Scalars['String']['output']>;
  nameSuffix?: Maybe<Scalars['String']['output']>;
  nationality?: Maybe<Country>;
  pointPerson?: Maybe<Person>;
  salutation?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  title?: Maybe<PersonTitle>;
};

export type EventParticipationForListSpouse = {
  __typename?: 'EventParticipationForListSpouse';
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isArchived: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  middleName?: Maybe<Scalars['String']['output']>;
  nameSuffix?: Maybe<Scalars['String']['output']>;
  title?: Maybe<PersonTitle>;
};

export type EventParticipationGroupDetailsInput = {
  eventCapacityId?: InputMaybe<Scalars['ID']['input']>;
  eventGroupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  votingKeyId?: InputMaybe<Scalars['ID']['input']>;
};

export enum EventParticipationStatus {
  Accepted = 'ACCEPTED',
  Attended = 'ATTENDED',
  Cancelled = 'CANCELLED',
  Invited = 'INVITED',
  Maybe = 'MAYBE',
  NoReply = 'NO_REPLY',
  Rejected = 'REJECTED'
}

export type EventParticipationTransportDetails = {
  comments?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['ISO8601Date']['input']>;
  direction: EventParticipationTransportDirection;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  time?: InputMaybe<Scalars['String']['input']>;
  transfer?: InputMaybe<Scalars['Boolean']['input']>;
  vip?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum EventParticipationTransportDirection {
  Arrival = 'ARRIVAL',
  Departure = 'DEPARTURE'
}

export type EventParticipationTravelBudgetDetails = {
  approvedTravelAmount?: InputMaybe<Scalars['String']['input']>;
  travelPaid?: InputMaybe<Scalars['Boolean']['input']>;
};

/** EventPreset */
export type EventPreset = {
  __typename?: 'EventPreset';
  columns: Array<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  event: Event;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  sort: PresetSort;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type EventTransport = {
  __typename?: 'EventTransport';
  comments?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  date?: Maybe<Scalars['ISO8601Date']['output']>;
  id: Scalars['ID']['output'];
  location: EventTransportLocation;
  participation: EventParticipation;
  time?: Maybe<Scalars['String']['output']>;
  transfer: Scalars['Boolean']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  vip: Scalars['Boolean']['output'];
};

/** EventTransportLocation */
export type EventTransportLocation = {
  __typename?: 'EventTransportLocation';
  createdAt: Scalars['ISO8601DateTime']['output'];
  event: Event;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** EventType */
export type EventType = {
  __typename?: 'EventType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type EventTypeCollection = {
  __typename?: 'EventTypeCollection';
  collection: Array<EventType>;
  export: CollectionExport;
  meta: CollectionMetadata;
};


export type EventTypeCollectionExportArgs = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  format?: InputMaybe<CollectionExportFormat>;
};

export type EventsFilters = {
  deprecated?: InputMaybe<Scalars['String']['input']>;
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EventsSort = {
  direction?: InputMaybe<Direction>;
  field?: InputMaybe<EventsSortFields>;
};

export enum EventsSortFields {
  StartDate = 'START_DATE'
}

/** Autogenerated input type of ExportEventParticipationsMutation */
export type ExportEventParticipationsMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['ID']['input'];
  exportColumns?: InputMaybe<Array<Scalars['String']['input']>>;
  filters?: InputMaybe<EventParticipantsFilters>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<ParticipationsForListSort>;
};

/** Autogenerated return type of ExportEventParticipationsMutation. */
export type ExportEventParticipationsMutationPayload = {
  __typename?: 'ExportEventParticipationsMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  csv?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

export enum ExportableEnum {
  Event = 'EVENT',
  EventParticipation = 'EVENT_PARTICIPATION',
  Institution = 'INSTITUTION',
  Person = 'PERSON',
  Room = 'ROOM',
  TagList = 'TAG_LIST'
}

export type ExportableField = {
  __typename?: 'ExportableField';
  group: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type Form = {
  __typename?: 'Form';
  adminNotification: Scalars['Boolean']['output'];
  adminNotificationEmails: Array<Scalars['String']['output']>;
  backgroundColor: Scalars['String']['output'];
  closedMessageBody: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  event: Event;
  header?: Maybe<ImageBlob>;
  id: Scalars['ID']['output'];
  isArchived: Scalars['Boolean']['output'];
  metaDescription?: Maybe<Scalars['String']['output']>;
  metaTitle?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  newSubmissionsCount: Scalars['Int']['output'];
  rows: Array<Scalars['JSON']['output']>;
  slug: Scalars['String']['output'];
  status: FormStatusEnum;
  submissionsCount: Scalars['Int']['output'];
  submitButtonLabel: Scalars['String']['output'];
  successMessageBody: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type FormCollection = {
  __typename?: 'FormCollection';
  collection: Array<Form>;
  export: CollectionExport;
  meta: CollectionMetadata;
};


export type FormCollectionExportArgs = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  format?: InputMaybe<CollectionExportFormat>;
};

export enum FormStatusEnum {
  Closed = 'CLOSED',
  Open = 'OPEN'
}

export type FormSubmission = {
  __typename?: 'FormSubmission';
  data: Array<FormSubmissionDataItem>;
  eventParticipation?: Maybe<EventParticipation>;
  form: Form;
  friendlyId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  sessionId: Scalars['String']['output'];
  startedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  status: FormSubmissionStatus;
  submittedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  submittedEmail?: Maybe<Scalars['String']['output']>;
  submittedFirstName?: Maybe<Scalars['String']['output']>;
  submittedLastName?: Maybe<Scalars['String']['output']>;
};

export type FormSubmissionCollection = {
  __typename?: 'FormSubmissionCollection';
  collection: Array<FormSubmission>;
  export: CollectionExport;
  meta: CollectionMetadata;
};


export type FormSubmissionCollectionExportArgs = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  format?: InputMaybe<CollectionExportFormat>;
};

export type FormSubmissionDataItem = {
  __typename?: 'FormSubmissionDataItem';
  displayValue?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  label: Scalars['String']['output'];
  value?: Maybe<Scalars['JSON']['output']>;
};

export type FormSubmissionMatchingPerson = {
  __typename?: 'FormSubmissionMatchingPerson';
  id: Scalars['ID']['output'];
  matches: Array<Scalars['String']['output']>;
  person: Person;
};

export enum FormSubmissionStatus {
  Archived = 'ARCHIVED',
  Mapped = 'MAPPED',
  Ongoing = 'ONGOING',
  Processed = 'PROCESSED',
  Rejected = 'REJECTED',
  Submitted = 'SUBMITTED'
}

export enum FormSubmissionStatuses {
  Abandoned = 'ABANDONED',
  Archived = 'ARCHIVED',
  Mapped = 'MAPPED',
  Processed = 'PROCESSED',
  Rejected = 'REJECTED',
  Submitted = 'SUBMITTED'
}

export type FormSubmissionsFilters = {
  status?: InputMaybe<FormSubmissionStatuses>;
};

export type FormsFilters = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Person gender */
export type Gender = {
  __typename?: 'Gender';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type GenderCollection = {
  __typename?: 'GenderCollection';
  collection: Array<Gender>;
  export: CollectionExport;
  meta: CollectionMetadata;
};


export type GenderCollectionExportArgs = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  format?: InputMaybe<CollectionExportFormat>;
};

export type GendersSort = {
  direction?: InputMaybe<Direction>;
  field?: InputMaybe<GendersSortFields>;
};

export enum GendersSortFields {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME'
}

export type ImageBlob = {
  __typename?: 'ImageBlob';
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type Institution = {
  __typename?: 'Institution';
  abbreviation?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  affiliations?: Maybe<AffiliationCollection>;
  categories?: Maybe<Array<InstitutionCategory>>;
  city?: Maybe<Scalars['String']['output']>;
  contacts?: Maybe<Array<InstitutionContact>>;
  country?: Maybe<Country>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  delegates13Pa?: Maybe<Scalars['Int']['output']>;
  delegates14Pa?: Maybe<Scalars['Int']['output']>;
  deskOfficer?: Maybe<Person>;
  id: Scalars['ID']['output'];
  inheritDeskOfficer: Scalars['Boolean']['output'];
  institutionMemberships?: Maybe<InstitutionMembershipCollection>;
  isArchived: Scalars['Boolean']['output'];
  isInternationalOrganization: Scalars['Boolean']['output'];
  jewishPopulationByJppi?: Maybe<Scalars['Int']['output']>;
  jewishPopulationCommunityEstimate?: Maybe<Scalars['Int']['output']>;
  languages: Array<Scalars['String']['output']>;
  locationEstablished?: Maybe<Scalars['String']['output']>;
  locations?: Maybe<Array<InstitutionLocation>>;
  mainLocation?: Maybe<InstitutionLocation>;
  members: PersonCollection;
  name: Scalars['String']['output'];
  officialName?: Maybe<Scalars['String']['output']>;
  pointPerson?: Maybe<Person>;
  presidentElectedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  presidentTermExpiresAt?: Maybe<Scalars['ISO8601Date']['output']>;
  primaryContactsValues: Array<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  tags: Array<Tag>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  wjcAffiliatedSince?: Maybe<Scalars['ISO8601Date']['output']>;
  yearEstablished?: Maybe<Scalars['Int']['output']>;
};


export type InstitutionAffiliationsArgs = {
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type InstitutionContactsArgs = {
  filters?: InputMaybe<ContactsFilters>;
};


export type InstitutionInstitutionMembershipsArgs = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type InstitutionMembersArgs = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
};

/** InstitutionCategory */
export type InstitutionCategory = {
  __typename?: 'InstitutionCategory';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parent?: Maybe<InstitutionCategory>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type InstitutionCategoryCollection = {
  __typename?: 'InstitutionCategoryCollection';
  collection: Array<InstitutionCategory>;
  export: CollectionExport;
  meta: CollectionMetadata;
};


export type InstitutionCategoryCollectionExportArgs = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  format?: InputMaybe<CollectionExportFormat>;
};

export type InstitutionCollection = {
  __typename?: 'InstitutionCollection';
  collection: Array<Institution>;
  export: CollectionExport;
  meta: CollectionMetadata;
};


export type InstitutionCollectionExportArgs = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  format?: InputMaybe<CollectionExportFormat>;
};

/** Institution contact */
export type InstitutionContact = {
  __typename?: 'InstitutionContact';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  institution: Institution;
  isPrimary: Scalars['Boolean']['output'];
  kind: ContactKind;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  value: Scalars['String']['output'];
};

export type InstitutionContactInput = {
  contactKindId?: InputMaybe<Scalars['ID']['input']>;
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type InstitutionDetailsInput = {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  delegates13Pa?: InputMaybe<Scalars['Int']['input']>;
  delegates14Pa?: InputMaybe<Scalars['Int']['input']>;
  establishedAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  inheritDeskOfficer?: InputMaybe<Scalars['Boolean']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isInternationalOrganization?: InputMaybe<Scalars['Boolean']['input']>;
  jewishPopulationByJppi?: InputMaybe<Scalars['Int']['input']>;
  jewishPopulationCommunityEstimate?: InputMaybe<Scalars['Int']['input']>;
  languages?: InputMaybe<Array<Scalars['String']['input']>>;
  locationEstablished?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  officialName?: InputMaybe<Scalars['String']['input']>;
  pointPersonId?: InputMaybe<Scalars['ID']['input']>;
  presidentElectedAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  presidentTermExpiresAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  wjcAffiliatedSince?: InputMaybe<Scalars['ISO8601Date']['input']>;
  yearEstablished?: InputMaybe<Scalars['Int']['input']>;
};

export type InstitutionGlobalContact = {
  contactKindId?: InputMaybe<Scalars['ID']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Office/Location of an institution */
export type InstitutionLocation = {
  __typename?: 'InstitutionLocation';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  address3?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  contacts?: Maybe<Array<InstitutionLocationContact>>;
  country?: Maybe<Country>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  institution: Institution;
  institutionMemberships?: Maybe<InstitutionMembershipCollection>;
  main: Scalars['Boolean']['output'];
  state?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  zip?: Maybe<Scalars['String']['output']>;
};


/** Office/Location of an institution */
export type InstitutionLocationContactsArgs = {
  filters?: InputMaybe<ContactsFilters>;
};


/** Office/Location of an institution */
export type InstitutionLocationInstitutionMembershipsArgs = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
};

/** Institution location contact */
export type InstitutionLocationContact = {
  __typename?: 'InstitutionLocationContact';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  institutionLocation: InstitutionLocation;
  isPrimary: Scalars['Boolean']['output'];
  kind: ContactKind;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  value: Scalars['String']['output'];
};

export type InstitutionLocationContactInput = {
  contactKindId?: InputMaybe<Scalars['ID']['input']>;
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type InstitutionLocationInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  address3?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  countryId?: InputMaybe<Scalars['ID']['input']>;
  main?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

/** Membership of a person in institution */
export type InstitutionMembership = {
  __typename?: 'InstitutionMembership';
  archivedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  contacts?: Maybe<Array<InstitutionMembershipContact>>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  department?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inOfficeSince?: Maybe<Scalars['ISO8601Date']['output']>;
  institution: Institution;
  institutionLocation?: Maybe<InstitutionLocation>;
  main: Scalars['Boolean']['output'];
  person: Person;
  position?: Maybe<Scalars['String']['output']>;
  positions?: Maybe<Array<InstitutionMembershipPosition>>;
  primaryContactPerson: Scalars['Boolean']['output'];
  room?: Maybe<Scalars['String']['output']>;
  secondaryPosition?: Maybe<Scalars['String']['output']>;
  termOfOfficeEndsAt?: Maybe<Scalars['ISO8601Date']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


/** Membership of a person in institution */
export type InstitutionMembershipContactsArgs = {
  filters?: InputMaybe<ContactsFilters>;
};

export type InstitutionMembershipCollection = {
  __typename?: 'InstitutionMembershipCollection';
  collection: Array<InstitutionMembership>;
  export: CollectionExport;
  meta: CollectionMetadata;
};


export type InstitutionMembershipCollectionExportArgs = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  format?: InputMaybe<CollectionExportFormat>;
};

/** Institution membership contact */
export type InstitutionMembershipContact = {
  __typename?: 'InstitutionMembershipContact';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  institutionMembership: InstitutionMembership;
  isPrimary: Scalars['Boolean']['output'];
  kind: ContactKind;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  value: Scalars['String']['output'];
};

export type InstitutionMembershipInput = {
  department?: InputMaybe<Scalars['String']['input']>;
  inOfficeSince?: InputMaybe<Scalars['ISO8601Date']['input']>;
  institutionLocationId?: InputMaybe<Scalars['ID']['input']>;
  main: Scalars['Boolean']['input'];
  position?: InputMaybe<Scalars['String']['input']>;
  primaryContactPerson?: InputMaybe<Scalars['Boolean']['input']>;
  room?: InputMaybe<Scalars['String']['input']>;
  secondaryPosition?: InputMaybe<Scalars['String']['input']>;
  termOfOfficeEndsAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
};

/** Institution membership position */
export type InstitutionMembershipPosition = {
  __typename?: 'InstitutionMembershipPosition';
  createdAt: Scalars['ISO8601DateTime']['output'];
  department?: Maybe<Scalars['String']['output']>;
  endsAt?: Maybe<Scalars['ISO8601Date']['output']>;
  id: Scalars['ID']['output'];
  institutionMembership: InstitutionMembership;
  position?: Maybe<Scalars['String']['output']>;
  startsAt?: Maybe<Scalars['ISO8601Date']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type InstitutionsFilters = {
  deskOfficerId?: InputMaybe<Scalars['ID']['input']>;
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  pointPersonId?: InputMaybe<Scalars['ID']['input']>;
};

export type InstitutionsSort = {
  direction?: InputMaybe<Direction>;
  field?: InputMaybe<InstitutionsSortFields>;
};

export enum InstitutionsSortFields {
  Name = 'NAME'
}

export type Language = {
  __typename?: 'Language';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type LanguageCollection = {
  __typename?: 'LanguageCollection';
  collection: Array<Language>;
  export: CollectionExport;
  meta: CollectionMetadata;
};


export type LanguageCollectionExportArgs = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  format?: InputMaybe<CollectionExportFormat>;
};

export type LanguagesSort = {
  direction?: InputMaybe<Direction>;
  field?: InputMaybe<LanguagesSortFieldsType>;
};

export enum LanguagesSortFieldsType {
  Name = 'NAME'
}

/** Lodge */
export type Lodge = {
  __typename?: 'Lodge';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  roomQuantities: Array<LodgeRoomQuantity>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  visibleInForms: Scalars['Boolean']['output'];
};

export type LodgeDetails = {
  name?: InputMaybe<Scalars['String']['input']>;
  visibleInForms?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LodgeRoomDetails = {
  contractedAmount?: InputMaybe<Scalars['Int']['input']>;
  priceAmount?: InputMaybe<Scalars['Float']['input']>;
  priceCurrency?: InputMaybe<Currency>;
  roomTypeId?: InputMaybe<Scalars['ID']['input']>;
  sharingAllowed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LodgeRoomQuantity = {
  __typename?: 'LodgeRoomQuantity';
  contractedAmount: Scalars['Int']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  lodge: Lodge;
  priceAmount?: Maybe<Scalars['Float']['output']>;
  priceCurrency?: Maybe<Currency>;
  roomType: RoomType;
  sharingAllowed: Scalars['Boolean']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type Me = {
  __typename?: 'Me';
  displayName: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  releaseNoteLastVisit?: Maybe<Scalars['ISO8601Date']['output']>;
};

export type MembershipContactInput = {
  contactKindId?: InputMaybe<Scalars['ID']['input']>;
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MembershipPositionInput = {
  department?: InputMaybe<Scalars['String']['input']>;
  endsAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  startsAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  archiveEvent?: Maybe<ArchiveEventMutationPayload>;
  archiveForm?: Maybe<ArchiveFormMutationPayload>;
  archiveFormSubmission?: Maybe<ArchiveFormSubmissionMutationPayload>;
  batchAddParticipants?: Maybe<BatchAddParticipantsMutationPayload>;
  batchAddParticipantsToGroup?: Maybe<BatchAddParticipantsToGroupMutationPayload>;
  batchAddParticipantsToSubEvent?: Maybe<BatchAddParticipantsToSubEventMutationPayload>;
  batchAddParticipantsWithTag?: Maybe<BatchAddParticipantsWithTagMutationPayload>;
  /** Batch add tags to people */
  batchAddTagsPeople?: Maybe<BatchAddTagsPeopleMutationPayload>;
  batchAssignCapacityToParticipants?: Maybe<BatchAssignCapacityToParticipantsMutationPayload>;
  batchChangeParticipationStatuses?: Maybe<BatchChangeParticipationStatusesMutationPayload>;
  batchChangeSubEventParticipationStatus?: Maybe<BatchChangeSubEventParticipationStatusMutationPayload>;
  /** Batch remove participants from event */
  batchRemoveParticipants?: Maybe<BatchRemoveParticipantsMutationPayload>;
  batchRemoveParticipantsFromGroup?: Maybe<BatchRemoveParticipantsFromGroupMutationPayload>;
  /** Batch remove participants from event */
  batchRemoveParticipantsFromSubEvent?: Maybe<BatchRemoveParticipantsFromSubEventMutationPayload>;
  batchSendEmail?: Maybe<BatchSendEmailMutationPayload>;
  /** Batch add tags to institution */
  batchTagInstitution?: Maybe<BatchTagInstitutionPayload>;
  /** Batch update people source */
  batchUpdatePeopleSource?: Maybe<BatchUpdatePeopleSourcePayload>;
  copyEmails?: Maybe<BatchCopyEmailsMutationPayload>;
  copyForm?: Maybe<CopyFormMutationPayload>;
  createAccommodation?: Maybe<CreateMutationPayload>;
  createAffiliation?: Maybe<CreateAffiliationMutationPayload>;
  createAffiliationType?: Maybe<CreateAffiliationTypeMutationPayload>;
  createCapacity?: Maybe<CreateCapacityMutationPayload>;
  createContactKind?: Maybe<CreateContactKindMutationPayload>;
  createCostCenter?: Maybe<CreateCostCenterMutationPayload>;
  createEmail?: Maybe<CreateEmailMutationPayload>;
  createEvent?: Maybe<CreateEventMutationPayload>;
  createEventGroup?: Maybe<CreateEventGroupMutationPayload>;
  createEventGroupType?: Maybe<CreateEventGroupTypeMutationPayload>;
  createEventParticipation?: Maybe<CreateEventParticipationMutationPayload>;
  createEventPreset?: Maybe<CreatePresetMutationPayload>;
  createEventType?: Maybe<CreateEventTypeMutationPayload>;
  createForm?: Maybe<CreateFormMutationPayload>;
  createInstitution?: Maybe<CreateInstitutionMutationPayload>;
  createInstitutionContact?: Maybe<CreateInstitutionContactPayload>;
  createInstitutionLocation?: Maybe<CreateInstitutionLocationMutationPayload>;
  createInstitutionLocationContact?: Maybe<CreateInstitutionLocationContactPayload>;
  /** Add existing person to institution as member */
  createInstitutionMembership?: Maybe<CreateInstitutionMembershipPayload>;
  createInstitutionMembershipContact?: Maybe<CreateMembershipContactPayload>;
  createInstitutionMembershipPosition?: Maybe<CreateMembershipPositionPayload>;
  createLodge?: Maybe<CreateEventLodgePayload>;
  createLodgeRoom?: Maybe<CreateEventLodgeRoomPayload>;
  createPassport?: Maybe<CreatePassportMutationPayload>;
  createPeopleRelationshipType?: Maybe<CreatePeopleRelationshipTypeMutationPayload>;
  createPerson?: Maybe<CreatePersonMutationPayload>;
  createPersonContact?: Maybe<CreatePersonContactPayload>;
  createPersonLocation?: Maybe<CreatePersonLocationMutationPayload>;
  createPersonLocationContact?: Maybe<CreatePersonLocationContactPayload>;
  createPersonPersonalEmail?: Maybe<CreatePersonPersonalEmailMutationPayload>;
  createPersonProfessionalEmail?: Maybe<CreatePersonProfessionalEmailMutationPayload>;
  createPersonRelationship?: Maybe<CreatePersonRelationshipMutationPayload>;
  createPersonTitle?: Maybe<CreatePersonTitleMutationPayload>;
  createReleaseNote?: Maybe<CreateReleaseNoteMutationPayload>;
  createRoom?: Maybe<CreateRoomMutationPayload>;
  createRoomType?: Maybe<CreateRoomTypeMutationPayload>;
  createSubEvent?: Maybe<CreateSubEventMutationPayload>;
  createSubEventParticipation?: Maybe<CreateSubEventParticipationMutationPayload>;
  createTable?: Maybe<CreateTableMutationPayload>;
  createTag?: Maybe<CreateTagMutationPayload>;
  createTagList?: Maybe<CreateTagListMutationPayload>;
  createTransportLocation?: Maybe<CreateTransportLocationMutationPayload>;
  createVotingGroup?: Maybe<CreateVotingGroupMutationPayload>;
  createVotingKey?: Maybe<CreateVotingKeyMutationPayload>;
  declineFormSubmission?: Maybe<DeclineFormSubmissionMutationPayload>;
  deleteAccommodation?: Maybe<DeleteMutationPayload>;
  deleteAffiliation?: Maybe<DeleteAffiliationMutationPayload>;
  deleteAffiliationType?: Maybe<DeleteAffiliationTypeMutationPayload>;
  deleteCapacity?: Maybe<DeleteCapacityMutationPayload>;
  deleteContactKind?: Maybe<DeleteContactKindMutationPayload>;
  deleteCostCenter?: Maybe<DeleteCostCenterMutationPayload>;
  deleteEmail?: Maybe<DeleteEmailMutationPayload>;
  deleteEventGroup?: Maybe<DeleteEventGroupMutationPayload>;
  deleteEventGroupType?: Maybe<DeleteEventGroupTypeMutationPayload>;
  deleteEventPreset?: Maybe<DeletePresetMutationPayload>;
  deleteEventType?: Maybe<DeleteEventTypeMutationPayload>;
  deleteInstitutionContact?: Maybe<DeleteInstitutionContactPayload>;
  deleteInstitutionLocation?: Maybe<DeleteInstitutionLocationMutationPayload>;
  deleteInstitutionLocationContact?: Maybe<DeleteInstitutionLocationContactPayload>;
  /** Delete person/member from institution */
  deleteInstitutionMembership?: Maybe<DeleteInstitutionMembershipPayload>;
  deleteInstitutionMembershipContact?: Maybe<DeleteMembershipContactPayload>;
  deleteInstitutionMembershipPosition?: Maybe<DeleteMembershipPositionPayload>;
  deleteLodge?: Maybe<DeleteEventLodgePayload>;
  deleteLodgeRoom?: Maybe<DeleteEventLodgeRoomPayload>;
  deleteParticipation?: Maybe<DeleteParticipationMutationPayload>;
  deletePassport?: Maybe<DeletePassportMutationPayload>;
  deletePeopleRelationshipType?: Maybe<DeletePeopleRelationshipTypeMutationPayload>;
  deletePersonContact?: Maybe<DeletePersonContactPayload>;
  deletePersonLocation?: Maybe<DeletePersonLocationMutationPayload>;
  deletePersonLocationContact?: Maybe<DeletePersonLocationContactPayload>;
  /** Deleting a person relationship */
  deletePersonRelationship?: Maybe<DeletePersonRelationshipMutationPayload>;
  deletePersonTitle?: Maybe<DeletePersonTitleMutationPayload>;
  deleteReleaseNote?: Maybe<DeleteReleaseNoteMutationPayload>;
  deleteRoom?: Maybe<DeleteRoomMutationPayload>;
  deleteRoomType?: Maybe<DeleteRoomTypeMutationPayload>;
  deleteSubEvent?: Maybe<DeleteSubEventMutationPayload>;
  deleteSubEventParticipation?: Maybe<DeleteSubEventParticipationMutationPayload>;
  deleteTable?: Maybe<DeleteTableMutationPayload>;
  deleteTag?: Maybe<DeleteTagMutationPayload>;
  deleteTagList?: Maybe<DeleteTagListMutationPayload>;
  deleteTransportLocation?: Maybe<DeleteTransportLocationMutationPayload>;
  deleteVotingGroup?: Maybe<DeleteVotingGroupMutationPayload>;
  deleteVotingKey?: Maybe<DeleteVotingKeyMutationPayload>;
  exportEventParticipations?: Maybe<ExportEventParticipationsMutationPayload>;
  placePerson?: Maybe<PlacePersonMutationPayload>;
  processFormSubmission?: Maybe<ProcessMutationPayload>;
  promoteFormSubmission?: Maybe<PromoteMutationPayload>;
  renderEmailPreview?: Maybe<RenderEmailPreviewMutationPayload>;
  resetFormSubmission?: Maybe<ResetMutationPayload>;
  restoreEvent?: Maybe<RestoreEventMutationPayload>;
  restoreForm?: Maybe<RestoreFormMutationPayload>;
  /** Restore person/member in institution */
  restoreInstitutionMembership?: Maybe<RestoreInstitutionMembershipPayload>;
  revertVersion?: Maybe<RevertVersionMutationPayload>;
  seatPerson?: Maybe<SeatPersonMutationPayload>;
  sendApprovalEmail?: Maybe<SendApprovalEmailMutationPayload>;
  sendEmail?: Maybe<SendEmailMutationPayload>;
  sendPreviewEmail?: Maybe<SendPreviewMutationPayload>;
  takeOutPerson?: Maybe<TakeOutPersonMutationPayload>;
  unseatPerson?: Maybe<UnseatPersonMutationPayload>;
  updateAccommodation?: Maybe<UpdateMutationPayload>;
  updateAffiliation?: Maybe<UpdateAffiliationMutationPayload>;
  updateAffiliationType?: Maybe<UpdateAffiliationTypeMutationPayload>;
  updateCapacity?: Maybe<UpdateCapacityMutationPayload>;
  updateContactKind?: Maybe<UpdateContactKindMutationPayload>;
  updateCostCenter?: Maybe<UpdateCostCenterMutationPayload>;
  updateCountryV2?: Maybe<UpdateCountryV2MutationPayload>;
  updateEmail?: Maybe<UpdateEmailMutationPayload>;
  /** Updates event capacities */
  updateEventCapacities?: Maybe<UpdateEventCapacitiesMutationPayload>;
  updateEventDetails?: Maybe<UpdateEventDetailsMutationPayload>;
  updateEventGroup?: Maybe<UpdateEventGroupMutationPayload>;
  updateEventGroupType?: Maybe<UpdateEventGroupTypeMutationPayload>;
  updateEventPreset?: Maybe<UpdatePresetMutationPayload>;
  updateEventType?: Maybe<UpdateEventTypeMutationPayload>;
  updateForm?: Maybe<UpdateFormMutationPayload>;
  /** Updates institution categories */
  updateInstitutionCategories?: Maybe<UpdateInstitutionCategoriesPayload>;
  updateInstitutionContact?: Maybe<UpdateInstitutionContactPayload>;
  updateInstitutionDetails?: Maybe<UpdateInstitutionDetailsPayload>;
  updateInstitutionLocation?: Maybe<UpdateInstitutionLocationMutationPayload>;
  updateInstitutionLocationContact?: Maybe<UpdateInstitutionLocationContactPayload>;
  /** Update person/member information in institution */
  updateInstitutionMembership?: Maybe<UpdateInstitutionMembershipPayload>;
  updateInstitutionMembershipContact?: Maybe<UpdateMembershipContactPayload>;
  updateInstitutionMembershipPosition?: Maybe<UpdateMembershipPositionPayload>;
  updateLastVisit?: Maybe<UpdateLastVisitMutationPayload>;
  updateLodge?: Maybe<UpdateEventLodgePayload>;
  updateLodgeRoom?: Maybe<UpdateEventLodgeRoomPayload>;
  updateParticipationContacts?: Maybe<UpdateContactsMutationPayload>;
  updateParticipationContactsV2?: Maybe<UpdateContactsMutationV2Payload>;
  updateParticipationDetails?: Maybe<UpdateDetailsMutationPayload>;
  updateParticipationGroups?: Maybe<UpdateGroupsMutationPayload>;
  updatePassport?: Maybe<UpdatePassportMutationPayload>;
  updatePeopleRelationshipType?: Maybe<UpdatePeopleRelationshipTypeMutationPayload>;
  updatePersonContact?: Maybe<UpdatePersonContactPayload>;
  /** Update person details */
  updatePersonDetails?: Maybe<UpdatePersonDetailsMutationPayload>;
  /** Update person location */
  updatePersonLocation?: Maybe<UpdatePersonLocationMutationPayload>;
  updatePersonLocationContact?: Maybe<UpdatePersonLocationContactPayload>;
  /** Update person relationship */
  updatePersonRelationship?: Maybe<UpdatePersonRelationshipMutationPayload>;
  updatePersonTitle?: Maybe<UpdatePersonTitleMutationPayload>;
  updateReleaseNote?: Maybe<UpdateReleaseNoteMutationPayload>;
  updateRoom?: Maybe<UpdateRoomMutationPayload>;
  updateRoomType?: Maybe<UpdateRoomTypeMutationPayload>;
  updateSubEvent?: Maybe<UpdateSubEventMutationPayload>;
  updateSubEventParticipation?: Maybe<UpdateSubEventParticipationMutationPayload>;
  updateTable?: Maybe<UpdateTableMutationPayload>;
  updateTag?: Maybe<UpdateTagMutationPayload>;
  updateTagList?: Maybe<UpdateTagListMutationPayload>;
  updateTransport?: Maybe<UpdateTransportMutationPayload>;
  updateTransportLocation?: Maybe<UpdateTransportLocationMutationPayload>;
  updateTravelBudget?: Maybe<UpdateTravelBudgetMutationPayload>;
  updateVotingGroup?: Maybe<UpdateVotingGroupMutationPayload>;
  updateVotingKey?: Maybe<UpdateVotingKeyMutationPayload>;
};


export type MutationArchiveEventArgs = {
  input: ArchiveEventMutationInput;
};


export type MutationArchiveFormArgs = {
  input: ArchiveFormMutationInput;
};


export type MutationArchiveFormSubmissionArgs = {
  input: ArchiveFormSubmissionMutationInput;
};


export type MutationBatchAddParticipantsArgs = {
  input: BatchAddParticipantsMutationInput;
};


export type MutationBatchAddParticipantsToGroupArgs = {
  input: BatchAddParticipantsToGroupMutationInput;
};


export type MutationBatchAddParticipantsToSubEventArgs = {
  input: BatchAddParticipantsToSubEventMutationInput;
};


export type MutationBatchAddParticipantsWithTagArgs = {
  input: BatchAddParticipantsWithTagMutationInput;
};


export type MutationBatchAddTagsPeopleArgs = {
  input: BatchAddTagsPeopleMutationInput;
};


export type MutationBatchAssignCapacityToParticipantsArgs = {
  input: BatchAssignCapacityToParticipantsMutationInput;
};


export type MutationBatchChangeParticipationStatusesArgs = {
  input: BatchChangeParticipationStatusesMutationInput;
};


export type MutationBatchChangeSubEventParticipationStatusArgs = {
  input: BatchChangeSubEventParticipationStatusMutationInput;
};


export type MutationBatchRemoveParticipantsArgs = {
  input: BatchRemoveParticipantsMutationInput;
};


export type MutationBatchRemoveParticipantsFromGroupArgs = {
  input: BatchRemoveParticipantsFromGroupMutationInput;
};


export type MutationBatchRemoveParticipantsFromSubEventArgs = {
  input: BatchRemoveParticipantsFromSubEventMutationInput;
};


export type MutationBatchSendEmailArgs = {
  input: BatchSendEmailMutationInput;
};


export type MutationBatchTagInstitutionArgs = {
  input: BatchTagInstitutionInput;
};


export type MutationBatchUpdatePeopleSourceArgs = {
  input: BatchUpdatePeopleSourceInput;
};


export type MutationCopyEmailsArgs = {
  input: BatchCopyEmailsMutationInput;
};


export type MutationCopyFormArgs = {
  input: CopyFormMutationInput;
};


export type MutationCreateAccommodationArgs = {
  input: CreateMutationInput;
};


export type MutationCreateAffiliationArgs = {
  input: CreateAffiliationMutationInput;
};


export type MutationCreateAffiliationTypeArgs = {
  input: CreateAffiliationTypeMutationInput;
};


export type MutationCreateCapacityArgs = {
  input: CreateCapacityMutationInput;
};


export type MutationCreateContactKindArgs = {
  input: CreateContactKindMutationInput;
};


export type MutationCreateCostCenterArgs = {
  input: CreateCostCenterMutationInput;
};


export type MutationCreateEmailArgs = {
  input: CreateEmailMutationInput;
};


export type MutationCreateEventArgs = {
  input: CreateEventMutationInput;
};


export type MutationCreateEventGroupArgs = {
  input: CreateEventGroupMutationInput;
};


export type MutationCreateEventGroupTypeArgs = {
  input: CreateEventGroupTypeMutationInput;
};


export type MutationCreateEventParticipationArgs = {
  input: CreateEventParticipationMutationInput;
};


export type MutationCreateEventPresetArgs = {
  input: CreatePresetMutationInput;
};


export type MutationCreateEventTypeArgs = {
  input: CreateEventTypeMutationInput;
};


export type MutationCreateFormArgs = {
  input: CreateFormMutationInput;
};


export type MutationCreateInstitutionArgs = {
  input: CreateInstitutionMutationInput;
};


export type MutationCreateInstitutionContactArgs = {
  input: CreateInstitutionContactInput;
};


export type MutationCreateInstitutionLocationArgs = {
  input: CreateInstitutionLocationMutationInput;
};


export type MutationCreateInstitutionLocationContactArgs = {
  input: CreateInstitutionLocationContactInput;
};


export type MutationCreateInstitutionMembershipArgs = {
  input: CreateInstitutionMembershipInput;
};


export type MutationCreateInstitutionMembershipContactArgs = {
  input: CreateMembershipContactInput;
};


export type MutationCreateInstitutionMembershipPositionArgs = {
  input: CreateMembershipPositionInput;
};


export type MutationCreateLodgeArgs = {
  input: CreateEventLodgeInput;
};


export type MutationCreateLodgeRoomArgs = {
  input: CreateEventLodgeRoomInput;
};


export type MutationCreatePassportArgs = {
  input: CreatePassportMutationInput;
};


export type MutationCreatePeopleRelationshipTypeArgs = {
  input: CreatePeopleRelationshipTypeMutationInput;
};


export type MutationCreatePersonArgs = {
  input: CreatePersonMutationInput;
};


export type MutationCreatePersonContactArgs = {
  input: CreatePersonContactInput;
};


export type MutationCreatePersonLocationArgs = {
  input: CreatePersonLocationMutationInput;
};


export type MutationCreatePersonLocationContactArgs = {
  input: CreatePersonLocationContactInput;
};


export type MutationCreatePersonPersonalEmailArgs = {
  input: CreatePersonPersonalEmailMutationInput;
};


export type MutationCreatePersonProfessionalEmailArgs = {
  input: CreatePersonProfessionalEmailMutationInput;
};


export type MutationCreatePersonRelationshipArgs = {
  input: CreatePersonRelationshipMutationInput;
};


export type MutationCreatePersonTitleArgs = {
  input: CreatePersonTitleMutationInput;
};


export type MutationCreateReleaseNoteArgs = {
  input: CreateReleaseNoteMutationInput;
};


export type MutationCreateRoomArgs = {
  input: CreateRoomMutationInput;
};


export type MutationCreateRoomTypeArgs = {
  input: CreateRoomTypeMutationInput;
};


export type MutationCreateSubEventArgs = {
  input: CreateSubEventMutationInput;
};


export type MutationCreateSubEventParticipationArgs = {
  input: CreateSubEventParticipationMutationInput;
};


export type MutationCreateTableArgs = {
  input: CreateTableMutationInput;
};


export type MutationCreateTagArgs = {
  input: CreateTagMutationInput;
};


export type MutationCreateTagListArgs = {
  input: CreateTagListMutationInput;
};


export type MutationCreateTransportLocationArgs = {
  input: CreateTransportLocationMutationInput;
};


export type MutationCreateVotingGroupArgs = {
  input: CreateVotingGroupMutationInput;
};


export type MutationCreateVotingKeyArgs = {
  input: CreateVotingKeyMutationInput;
};


export type MutationDeclineFormSubmissionArgs = {
  input: DeclineFormSubmissionMutationInput;
};


export type MutationDeleteAccommodationArgs = {
  input: DeleteMutationInput;
};


export type MutationDeleteAffiliationArgs = {
  input: DeleteAffiliationMutationInput;
};


export type MutationDeleteAffiliationTypeArgs = {
  input: DeleteAffiliationTypeMutationInput;
};


export type MutationDeleteCapacityArgs = {
  input: DeleteCapacityMutationInput;
};


export type MutationDeleteContactKindArgs = {
  input: DeleteContactKindMutationInput;
};


export type MutationDeleteCostCenterArgs = {
  input: DeleteCostCenterMutationInput;
};


export type MutationDeleteEmailArgs = {
  input: DeleteEmailMutationInput;
};


export type MutationDeleteEventGroupArgs = {
  input: DeleteEventGroupMutationInput;
};


export type MutationDeleteEventGroupTypeArgs = {
  input: DeleteEventGroupTypeMutationInput;
};


export type MutationDeleteEventPresetArgs = {
  input: DeletePresetMutationInput;
};


export type MutationDeleteEventTypeArgs = {
  input: DeleteEventTypeMutationInput;
};


export type MutationDeleteInstitutionContactArgs = {
  input: DeleteInstitutionContactInput;
};


export type MutationDeleteInstitutionLocationArgs = {
  input: DeleteInstitutionLocationMutationInput;
};


export type MutationDeleteInstitutionLocationContactArgs = {
  input: DeleteInstitutionLocationContactInput;
};


export type MutationDeleteInstitutionMembershipArgs = {
  input: DeleteInstitutionMembershipInput;
};


export type MutationDeleteInstitutionMembershipContactArgs = {
  input: DeleteMembershipContactInput;
};


export type MutationDeleteInstitutionMembershipPositionArgs = {
  input: DeleteMembershipPositionInput;
};


export type MutationDeleteLodgeArgs = {
  input: DeleteEventLodgeInput;
};


export type MutationDeleteLodgeRoomArgs = {
  input: DeleteEventLodgeRoomInput;
};


export type MutationDeleteParticipationArgs = {
  input: DeleteParticipationMutationInput;
};


export type MutationDeletePassportArgs = {
  input: DeletePassportMutationInput;
};


export type MutationDeletePeopleRelationshipTypeArgs = {
  input: DeletePeopleRelationshipTypeMutationInput;
};


export type MutationDeletePersonContactArgs = {
  input: DeletePersonContactInput;
};


export type MutationDeletePersonLocationArgs = {
  input: DeletePersonLocationMutationInput;
};


export type MutationDeletePersonLocationContactArgs = {
  input: DeletePersonLocationContactInput;
};


export type MutationDeletePersonRelationshipArgs = {
  input: DeletePersonRelationshipMutationInput;
};


export type MutationDeletePersonTitleArgs = {
  input: DeletePersonTitleMutationInput;
};


export type MutationDeleteReleaseNoteArgs = {
  input: DeleteReleaseNoteMutationInput;
};


export type MutationDeleteRoomArgs = {
  input: DeleteRoomMutationInput;
};


export type MutationDeleteRoomTypeArgs = {
  input: DeleteRoomTypeMutationInput;
};


export type MutationDeleteSubEventArgs = {
  input: DeleteSubEventMutationInput;
};


export type MutationDeleteSubEventParticipationArgs = {
  input: DeleteSubEventParticipationMutationInput;
};


export type MutationDeleteTableArgs = {
  input: DeleteTableMutationInput;
};


export type MutationDeleteTagArgs = {
  input: DeleteTagMutationInput;
};


export type MutationDeleteTagListArgs = {
  input: DeleteTagListMutationInput;
};


export type MutationDeleteTransportLocationArgs = {
  input: DeleteTransportLocationMutationInput;
};


export type MutationDeleteVotingGroupArgs = {
  input: DeleteVotingGroupMutationInput;
};


export type MutationDeleteVotingKeyArgs = {
  input: DeleteVotingKeyMutationInput;
};


export type MutationExportEventParticipationsArgs = {
  input: ExportEventParticipationsMutationInput;
};


export type MutationPlacePersonArgs = {
  input: PlacePersonMutationInput;
};


export type MutationProcessFormSubmissionArgs = {
  input: ProcessMutationInput;
};


export type MutationPromoteFormSubmissionArgs = {
  input: PromoteMutationInput;
};


export type MutationRenderEmailPreviewArgs = {
  input: RenderEmailPreviewMutationInput;
};


export type MutationResetFormSubmissionArgs = {
  input: ResetMutationInput;
};


export type MutationRestoreEventArgs = {
  input: RestoreEventMutationInput;
};


export type MutationRestoreFormArgs = {
  input: RestoreFormMutationInput;
};


export type MutationRestoreInstitutionMembershipArgs = {
  input: RestoreInstitutionMembershipInput;
};


export type MutationRevertVersionArgs = {
  input: RevertVersionMutationInput;
};


export type MutationSeatPersonArgs = {
  input: SeatPersonMutationInput;
};


export type MutationSendApprovalEmailArgs = {
  input: SendApprovalEmailMutationInput;
};


export type MutationSendEmailArgs = {
  input: SendEmailMutationInput;
};


export type MutationSendPreviewEmailArgs = {
  input: SendPreviewMutationInput;
};


export type MutationTakeOutPersonArgs = {
  input: TakeOutPersonMutationInput;
};


export type MutationUnseatPersonArgs = {
  input: UnseatPersonMutationInput;
};


export type MutationUpdateAccommodationArgs = {
  input: UpdateMutationInput;
};


export type MutationUpdateAffiliationArgs = {
  input: UpdateAffiliationMutationInput;
};


export type MutationUpdateAffiliationTypeArgs = {
  input: UpdateAffiliationTypeMutationInput;
};


export type MutationUpdateCapacityArgs = {
  input: UpdateCapacityMutationInput;
};


export type MutationUpdateContactKindArgs = {
  input: UpdateContactKindMutationInput;
};


export type MutationUpdateCostCenterArgs = {
  input: UpdateCostCenterMutationInput;
};


export type MutationUpdateCountryV2Args = {
  input: UpdateCountryV2MutationInput;
};


export type MutationUpdateEmailArgs = {
  input: UpdateEmailMutationInput;
};


export type MutationUpdateEventCapacitiesArgs = {
  input: UpdateEventCapacitiesMutationInput;
};


export type MutationUpdateEventDetailsArgs = {
  input: UpdateEventDetailsMutationInput;
};


export type MutationUpdateEventGroupArgs = {
  input: UpdateEventGroupMutationInput;
};


export type MutationUpdateEventGroupTypeArgs = {
  input: UpdateEventGroupTypeMutationInput;
};


export type MutationUpdateEventPresetArgs = {
  input: UpdatePresetMutationInput;
};


export type MutationUpdateEventTypeArgs = {
  input: UpdateEventTypeMutationInput;
};


export type MutationUpdateFormArgs = {
  input: UpdateFormMutationInput;
};


export type MutationUpdateInstitutionCategoriesArgs = {
  input: UpdateInstitutionCategoriesInput;
};


export type MutationUpdateInstitutionContactArgs = {
  input: UpdateInstitutionContactInput;
};


export type MutationUpdateInstitutionDetailsArgs = {
  input: UpdateInstitutionDetailsInput;
};


export type MutationUpdateInstitutionLocationArgs = {
  input: UpdateInstitutionLocationMutationInput;
};


export type MutationUpdateInstitutionLocationContactArgs = {
  input: UpdateInstitutionLocationContactInput;
};


export type MutationUpdateInstitutionMembershipArgs = {
  input: UpdateInstitutionMembershipInput;
};


export type MutationUpdateInstitutionMembershipContactArgs = {
  input: UpdateMembershipContactInput;
};


export type MutationUpdateInstitutionMembershipPositionArgs = {
  input: UpdateMembershipPositionInput;
};


export type MutationUpdateLastVisitArgs = {
  input: UpdateLastVisitMutationInput;
};


export type MutationUpdateLodgeArgs = {
  input: UpdateEventLodgeInput;
};


export type MutationUpdateLodgeRoomArgs = {
  input: UpdateEventLodgeRoomInput;
};


export type MutationUpdateParticipationContactsArgs = {
  input: UpdateContactsMutationInput;
};


export type MutationUpdateParticipationContactsV2Args = {
  input: UpdateContactsMutationV2Input;
};


export type MutationUpdateParticipationDetailsArgs = {
  input: UpdateDetailsMutationInput;
};


export type MutationUpdateParticipationGroupsArgs = {
  input: UpdateGroupsMutationInput;
};


export type MutationUpdatePassportArgs = {
  input: UpdatePassportMutationInput;
};


export type MutationUpdatePeopleRelationshipTypeArgs = {
  input: UpdatePeopleRelationshipTypeMutationInput;
};


export type MutationUpdatePersonContactArgs = {
  input: UpdatePersonContactInput;
};


export type MutationUpdatePersonDetailsArgs = {
  input: UpdatePersonDetailsMutationInput;
};


export type MutationUpdatePersonLocationArgs = {
  input: UpdatePersonLocationMutationInput;
};


export type MutationUpdatePersonLocationContactArgs = {
  input: UpdatePersonLocationContactInput;
};


export type MutationUpdatePersonRelationshipArgs = {
  input: UpdatePersonRelationshipMutationInput;
};


export type MutationUpdatePersonTitleArgs = {
  input: UpdatePersonTitleMutationInput;
};


export type MutationUpdateReleaseNoteArgs = {
  input: UpdateReleaseNoteMutationInput;
};


export type MutationUpdateRoomArgs = {
  input: UpdateRoomMutationInput;
};


export type MutationUpdateRoomTypeArgs = {
  input: UpdateRoomTypeMutationInput;
};


export type MutationUpdateSubEventArgs = {
  input: UpdateSubEventMutationInput;
};


export type MutationUpdateSubEventParticipationArgs = {
  input: UpdateSubEventParticipationMutationInput;
};


export type MutationUpdateTableArgs = {
  input: UpdateTableMutationInput;
};


export type MutationUpdateTagArgs = {
  input: UpdateTagMutationInput;
};


export type MutationUpdateTagListArgs = {
  input: UpdateTagListMutationInput;
};


export type MutationUpdateTransportArgs = {
  input: UpdateTransportMutationInput;
};


export type MutationUpdateTransportLocationArgs = {
  input: UpdateTransportLocationMutationInput;
};


export type MutationUpdateTravelBudgetArgs = {
  input: UpdateTravelBudgetMutationInput;
};


export type MutationUpdateVotingGroupArgs = {
  input: UpdateVotingGroupMutationInput;
};


export type MutationUpdateVotingKeyArgs = {
  input: UpdateVotingKeyMutationInput;
};

/** Names display order enum */
export enum NamesDisplayOrder {
  /** First name, last name */
  FirstNameFirst = 'FIRST_NAME_FIRST',
  /** Last name, first name */
  LastNameFirst = 'LAST_NAME_FIRST'
}

/** A type for sorting plural fields */
export type Pagination = {
  /** Items per page */
  items?: InputMaybe<Scalars['Int']['input']>;
  /** Page to fetch */
  page?: InputMaybe<Scalars['Int']['input']>;
};

export enum PaidStatus {
  No = 'NO',
  Split = 'SPLIT',
  Yes = 'YES'
}

export type ParticipationContactDetails = {
  carbonCopy: Scalars['Boolean']['input'];
  contactId: Scalars['ID']['input'];
  direct: Scalars['Boolean']['input'];
};

export type ParticipationsForListSort = {
  direction?: InputMaybe<Direction>;
  field?: InputMaybe<Scalars['String']['input']>;
};

/** Passport */
export type Passport = {
  __typename?: 'Passport';
  active: Scalars['Boolean']['output'];
  birthday?: Maybe<Scalars['ISO8601Date']['output']>;
  country?: Maybe<Country>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  expiresAt?: Maybe<Scalars['ISO8601Date']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  includeInWjcExports: Scalars['Boolean']['output'];
  issuedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type PeopleFilters = {
  deskOfficerId?: InputMaybe<Scalars['ID']['input']>;
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  pointPersonId?: InputMaybe<Scalars['ID']['input']>;
  updatedAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

export type PeopleSort = {
  direction?: InputMaybe<Direction>;
  field?: InputMaybe<PeopleSortFields>;
};

export enum PeopleSortFields {
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME'
}

export type Person = {
  __typename?: 'Person';
  alternativeTitle?: Maybe<Scalars['String']['output']>;
  birthday?: Maybe<Scalars['ISO8601Date']['output']>;
  contacts?: Maybe<Array<PersonContact>>;
  country?: Maybe<Country>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  deceasedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  deskOfficers: Array<Person>;
  /** Use this when you need to display the name */
  displayName: Scalars['String']['output'];
  emails?: Maybe<Scalars['String']['output']>;
  eventParticipations?: Maybe<EventParticipationCollection>;
  facebooks?: Maybe<Scalars['String']['output']>;
  faxes?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  gender?: Maybe<Gender>;
  id: Scalars['ID']['output'];
  ims?: Maybe<Scalars['String']['output']>;
  includeInWjcExports: Scalars['Boolean']['output'];
  initials: Scalars['String']['output'];
  institutionMemberships: Array<InstitutionMembership>;
  isArchived: Scalars['Boolean']['output'];
  isDeceased: Scalars['Boolean']['output'];
  isDeskOfficer: Scalars['Boolean']['output'];
  isNationalExpert: Scalars['Boolean']['output'];
  isWjcContact: Scalars['Boolean']['output'];
  languages: Array<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  locations?: Maybe<Array<PersonLocation>>;
  middleName?: Maybe<Scalars['String']['output']>;
  mobiles?: Maybe<Scalars['String']['output']>;
  nameSuffix?: Maybe<Scalars['String']['output']>;
  namesDisplayOrder: NamesDisplayOrder;
  nationality?: Maybe<Country>;
  passports: Array<Passport>;
  phones?: Maybe<Scalars['String']['output']>;
  pointPerson?: Maybe<Person>;
  primaryDeskOfficer?: Maybe<Person>;
  primaryInstitutionMembership?: Maybe<InstitutionMembership>;
  relationships: Array<Relationship>;
  salutation?: Maybe<Scalars['String']['output']>;
  skypes?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  tags: Array<Tag>;
  title?: Maybe<PersonTitle>;
  twitters?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  websites?: Maybe<Scalars['String']['output']>;
  youtubes?: Maybe<Scalars['String']['output']>;
};


export type PersonContactsArgs = {
  filters?: InputMaybe<ContactsFilters>;
};


export type PersonEventParticipationsArgs = {
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type PersonCollection = {
  __typename?: 'PersonCollection';
  collection: Array<Person>;
  export: CollectionExport;
  meta: CollectionMetadata;
};


export type PersonCollectionExportArgs = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  format?: InputMaybe<CollectionExportFormat>;
};

/** Person contact */
export type PersonContact = {
  __typename?: 'PersonContact';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  isPrimary: Scalars['Boolean']['output'];
  kind: ContactKind;
  person: Person;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  value: Scalars['String']['output'];
};

export type PersonContactInput = {
  contactKindId?: InputMaybe<Scalars['ID']['input']>;
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type PersonDetailsInput = {
  alternativeTitle?: InputMaybe<Scalars['String']['input']>;
  birthday?: InputMaybe<Scalars['ISO8601Date']['input']>;
  deceasedAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  genderId?: InputMaybe<Scalars['ID']['input']>;
  includeInWjcExports?: InputMaybe<Scalars['Boolean']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isDeceased?: InputMaybe<Scalars['Boolean']['input']>;
  isDeskOfficer?: InputMaybe<Scalars['Boolean']['input']>;
  isNationalExpert?: InputMaybe<Scalars['Boolean']['input']>;
  isWjcContact?: InputMaybe<Scalars['Boolean']['input']>;
  languages?: InputMaybe<Array<Scalars['String']['input']>>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  nameSuffix?: InputMaybe<Scalars['String']['input']>;
  namesDisplayOrder?: InputMaybe<NamesDisplayOrder>;
  nationalityId?: InputMaybe<Scalars['ID']['input']>;
  pointPersonId?: InputMaybe<Scalars['ID']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  titleId?: InputMaybe<Scalars['ID']['input']>;
};

export type PersonLocation = {
  __typename?: 'PersonLocation';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  address3?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  contacts?: Maybe<Array<PersonLocationContact>>;
  country?: Maybe<Country>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  person: Person;
  starred: Scalars['Boolean']['output'];
  state?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  zip?: Maybe<Scalars['String']['output']>;
};


export type PersonLocationContactsArgs = {
  filters?: InputMaybe<ContactsFilters>;
};

/** Person location contact */
export type PersonLocationContact = {
  __typename?: 'PersonLocationContact';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  isPrimary: Scalars['Boolean']['output'];
  kind: ContactKind;
  personLocation: PersonLocation;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  value: Scalars['String']['output'];
};

export type PersonLocationContactInput = {
  contactKindId?: InputMaybe<Scalars['ID']['input']>;
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type PersonLocationInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  address3?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  countryId?: InputMaybe<Scalars['ID']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type PersonPassportInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  birthday?: InputMaybe<Scalars['ISO8601Date']['input']>;
  countryId?: InputMaybe<Scalars['ID']['input']>;
  expiresAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  issuedAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
};

/** Person title */
export type PersonTitle = {
  __typename?: 'PersonTitle';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type PersonTitleCollection = {
  __typename?: 'PersonTitleCollection';
  collection: Array<PersonTitle>;
  export: CollectionExport;
  meta: CollectionMetadata;
};


export type PersonTitleCollectionExportArgs = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  format?: InputMaybe<CollectionExportFormat>;
};

export type PersonTitleSort = {
  direction?: InputMaybe<Direction>;
  field?: InputMaybe<PersonTitlesSortFields>;
};

export enum PersonTitlesSortFields {
  CreatedAt = 'CREATED_AT',
  Title = 'TITLE'
}

export type PersonalContactInput = {
  contactKindId?: InputMaybe<Scalars['ID']['input']>;
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of PlacePersonMutation */
export type PlacePersonMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventParticipationId: Scalars['ID']['input'];
  left: Scalars['Int']['input'];
  roomId: Scalars['ID']['input'];
  top: Scalars['Int']['input'];
};

/** Autogenerated return type of PlacePersonMutation. */
export type PlacePersonMutationPayload = {
  __typename?: 'PlacePersonMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  room?: Maybe<Room>;
  success: Scalars['Boolean']['output'];
};

export type Policy = {
  __typename?: 'Policy';
  abilities: PolicyAbility;
  resource: PolicyResourceEnum;
};

export type PolicyAbility = {
  __typename?: 'PolicyAbility';
  edit: Scalars['Boolean']['output'];
  list: Scalars['Boolean']['output'];
  view: Scalars['Boolean']['output'];
};

export enum PolicyResourceEnum {
  Changelogs = 'CHANGELOGS',
  Countries = 'COUNTRIES',
  Emails = 'EMAILS',
  Events = 'EVENTS',
  Forms = 'FORMS',
  Institutions = 'INSTITUTIONS',
  Others = 'OTHERS',
  Participants = 'PARTICIPANTS',
  People = 'PEOPLE',
  ReleaseNotes = 'RELEASE_NOTES',
  Rooms = 'ROOMS',
  TagLists = 'TAG_LISTS',
  User = 'USER'
}

export type PresetSort = {
  __typename?: 'PresetSort';
  direction: Direction;
  field: Scalars['String']['output'];
};

export type PresetSortInput = {
  direction?: InputMaybe<Direction>;
  field?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of ProcessMutation */
export type ProcessMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  formSubmissionId: Scalars['ID']['input'];
};

/** Autogenerated return type of ProcessMutation. */
export type ProcessMutationPayload = {
  __typename?: 'ProcessMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  formSubmission?: Maybe<FormSubmission>;
  success: Scalars['Boolean']['output'];
};

export type ProfessionalContactInput = {
  contactKindId?: InputMaybe<Scalars['ID']['input']>;
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of PromoteMutation */
export type PromoteMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  formSubmissionId: Scalars['ID']['input'];
  personId: Scalars['ID']['input'];
};

/** Autogenerated return type of PromoteMutation. */
export type PromoteMutationPayload = {
  __typename?: 'PromoteMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  formSubmission?: Maybe<FormSubmission>;
  success: Scalars['Boolean']['output'];
};

export type Query = {
  __typename?: 'Query';
  affiliationTypes: AffiliationTypeCollection;
  capacities: CapacityCollection;
  changelog: ChangelogItemCollection;
  contactKind?: Maybe<ContactKind>;
  contactKinds: ContactKindCollection;
  costCenters: CostCenterCollection;
  countries: CountryCollection;
  email?: Maybe<Email>;
  emails: Array<Email>;
  event?: Maybe<Event>;
  eventGroupTypes: EventGroupTypeCollection;
  eventLodge?: Maybe<Lodge>;
  eventParticipation?: Maybe<EventParticipation>;
  eventParticipationsForList: EventParticipationForListCollection;
  eventTypes: EventTypeCollection;
  eventVotingGroup?: Maybe<VotingGroup>;
  events: EventCollection;
  exportableFields: Array<ExportableField>;
  form?: Maybe<Form>;
  formSubmission?: Maybe<FormSubmission>;
  formSubmissionMatchingPeople?: Maybe<Array<FormSubmissionMatchingPerson>>;
  formSubmissions: FormSubmissionCollection;
  forms: FormCollection;
  gender?: Maybe<Gender>;
  genders: GenderCollection;
  institution?: Maybe<Institution>;
  institutionCategories: InstitutionCategoryCollection;
  institutionLocation?: Maybe<InstitutionLocation>;
  institutionMembership?: Maybe<InstitutionMembership>;
  institutionMemberships: InstitutionMembershipCollection;
  institutions: InstitutionCollection;
  languages: LanguageCollection;
  me: Me;
  people: PersonCollection;
  person?: Maybe<Person>;
  personTitles: PersonTitleCollection;
  policies: Array<Policy>;
  relationshipTypes: RelationshipTypeCollection;
  releaseNote?: Maybe<ReleaseNote>;
  releaseNotes: ReleaseNoteCollection;
  room?: Maybe<Room>;
  roomTypes: RoomTypeCollection;
  rooms: RoomCollection;
  tagList?: Maybe<TagList>;
  tagListInstitutions: InstitutionCollection;
  tagListPeople: PersonCollection;
  tagLists: TagListCollection;
  tags: TagCollection;
  user?: Maybe<User>;
  users: UserCollection;
};


export type QueryAffiliationTypesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCapacitiesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryChangelogArgs = {
  id: Scalars['ID']['input'];
  pagination?: InputMaybe<Pagination>;
  type: ChangelogModelEnum;
};


export type QueryContactKindArgs = {
  id: Scalars['ID']['input'];
};


export type QueryContactKindsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCostCentersArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCountriesArgs = {
  filters?: InputMaybe<CountriesFilters>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<CountriesSort>;
};


export type QueryEmailArgs = {
  id: Scalars['ID']['input'];
};


export type QueryEmailsArgs = {
  eventId: Scalars['ID']['input'];
};


export type QueryEventArgs = {
  id: Scalars['ID']['input'];
};


export type QueryEventGroupTypesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryEventLodgeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryEventParticipationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryEventParticipationsForListArgs = {
  eventId: Scalars['ID']['input'];
  filters?: InputMaybe<EventParticipantsFilters>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<ParticipationsForListSort>;
};


export type QueryEventTypesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryEventVotingGroupArgs = {
  id: Scalars['ID']['input'];
};


export type QueryEventsArgs = {
  advancedFilters?: InputMaybe<Array<AdvancedFilter>>;
  filters?: InputMaybe<EventsFilters>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<EventsSort>;
};


export type QueryExportableFieldsArgs = {
  exportable: ExportableEnum;
};


export type QueryFormArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFormSubmissionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFormSubmissionMatchingPeopleArgs = {
  submissionId: Scalars['ID']['input'];
};


export type QueryFormSubmissionsArgs = {
  filters?: InputMaybe<FormSubmissionsFilters>;
  formId: Scalars['ID']['input'];
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFormsArgs = {
  eventId: Scalars['ID']['input'];
  filters?: InputMaybe<FormsFilters>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGenderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGendersArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<GendersSort>;
};


export type QueryInstitutionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInstitutionCategoriesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryInstitutionLocationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInstitutionMembershipArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInstitutionMembershipsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryInstitutionsArgs = {
  advancedFilters?: InputMaybe<Array<AdvancedFilter>>;
  filters?: InputMaybe<InstitutionsFilters>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<InstitutionsSort>;
};


export type QueryLanguagesArgs = {
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<LanguagesSort>;
};


export type QueryPeopleArgs = {
  advancedFilters?: InputMaybe<Array<AdvancedFilter>>;
  filters?: InputMaybe<PeopleFilters>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<PeopleSort>;
};


export type QueryPersonArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPersonTitlesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<PersonTitleSort>;
};


export type QueryRelationshipTypesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryReleaseNoteArgs = {
  id: Scalars['ID']['input'];
};


export type QueryReleaseNotesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryRoomArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRoomTypesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryRoomsArgs = {
  eventId: Scalars['ID']['input'];
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTagListArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTagListInstitutionsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<TagListInstitutionsSort>;
  tagListId: Scalars['ID']['input'];
};


export type QueryTagListPeopleArgs = {
  filters?: InputMaybe<TagListContactsFilters>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<TagListPeopleSort>;
  tagListId: Scalars['ID']['input'];
};


export type QueryTagListsArgs = {
  advancedFilters?: InputMaybe<Array<AdvancedFilter>>;
  filters?: InputMaybe<TagListsFilters>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTagsArgs = {
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUsersArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
};

/** Relationship */
export type Relationship = {
  __typename?: 'Relationship';
  from: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  person: Person;
  relatedPerson: Person;
  relationshipType: RelationshipType;
};

/** RelationshipType */
export type RelationshipType = {
  __typename?: 'RelationshipType';
  category: RelationshipTypeEnum;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  opposite?: Maybe<Scalars['String']['output']>;
};

export type RelationshipTypeCollection = {
  __typename?: 'RelationshipTypeCollection';
  collection: Array<RelationshipType>;
  export: CollectionExport;
  meta: CollectionMetadata;
};


export type RelationshipTypeCollectionExportArgs = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  format?: InputMaybe<CollectionExportFormat>;
};

export enum RelationshipTypeEnum {
  Family = 'FAMILY',
  Professional = 'PROFESSIONAL'
}

export type ReleaseNote = {
  __typename?: 'ReleaseNote';
  createdAt: Scalars['ISO8601DateTime']['output'];
  date: Scalars['ISO8601Date']['output'];
  id: Scalars['ID']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type ReleaseNoteCollection = {
  __typename?: 'ReleaseNoteCollection';
  collection: Array<ReleaseNote>;
  export: CollectionExport;
  meta: CollectionMetadata;
};


export type ReleaseNoteCollectionExportArgs = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  format?: InputMaybe<CollectionExportFormat>;
};

/** Autogenerated input type of RenderEmailPreviewMutation */
export type RenderEmailPreviewMutationInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  emailId: Scalars['ID']['input'];
  fake?: InputMaybe<Scalars['Boolean']['input']>;
  participationId?: InputMaybe<Scalars['ID']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Autogenerated return type of RenderEmailPreviewMutation. */
export type RenderEmailPreviewMutationPayload = {
  __typename?: 'RenderEmailPreviewMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  preview?: Maybe<EmailPreview>;
  success: Scalars['Boolean']['output'];
};

export enum ReplyChannels {
  Card = 'CARD',
  EMail = 'E_MAIL',
  Fax = 'FAX',
  Form = 'FORM',
  InstantMessage = 'INSTANT_MESSAGE',
  Letter = 'LETTER',
  Phone = 'PHONE',
  Post = 'POST',
  Sms = 'SMS',
  Verbal = 'VERBAL',
  Web = 'WEB',
  Whatsapp = 'WHATSAPP'
}

/** Autogenerated input type of ResetMutation */
export type ResetMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  formSubmissionId: Scalars['ID']['input'];
};

/** Autogenerated return type of ResetMutation. */
export type ResetMutationPayload = {
  __typename?: 'ResetMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  formSubmission?: Maybe<FormSubmission>;
  success: Scalars['Boolean']['output'];
};

export enum Response {
  Maybe = 'MAYBE',
  No = 'NO',
  Yes = 'YES'
}

/** Autogenerated input type of RestoreEventMutation */
export type RestoreEventMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['ID']['input'];
};

/** Autogenerated return type of RestoreEventMutation. */
export type RestoreEventMutationPayload = {
  __typename?: 'RestoreEventMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  event?: Maybe<Event>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of RestoreFormMutation */
export type RestoreFormMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  formId: Scalars['ID']['input'];
};

/** Autogenerated return type of RestoreFormMutation. */
export type RestoreFormMutationPayload = {
  __typename?: 'RestoreFormMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  form?: Maybe<Form>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of RestoreInstitutionMembership */
export type RestoreInstitutionMembershipInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  institutionMembershipId: Scalars['ID']['input'];
};

/** Autogenerated return type of RestoreInstitutionMembership. */
export type RestoreInstitutionMembershipPayload = {
  __typename?: 'RestoreInstitutionMembershipPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  institutionMembership?: Maybe<InstitutionMembership>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of RevertVersionMutation */
export type RevertVersionMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of RevertVersionMutation. */
export type RevertVersionMutationPayload = {
  __typename?: 'RevertVersionMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

export type Room = {
  __typename?: 'Room';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  landscape: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  standingParticipants: Array<StandingParticipant>;
  subEvent: SubEvent;
  tables: Array<Table>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type RoomCollection = {
  __typename?: 'RoomCollection';
  collection: Array<Room>;
  export: CollectionExport;
  meta: CollectionMetadata;
};


export type RoomCollectionExportArgs = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  format?: InputMaybe<CollectionExportFormat>;
};

/** RoomType */
export type RoomType = {
  __typename?: 'RoomType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type RoomTypeCollection = {
  __typename?: 'RoomTypeCollection';
  collection: Array<RoomType>;
  export: CollectionExport;
  meta: CollectionMetadata;
};


export type RoomTypeCollectionExportArgs = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  format?: InputMaybe<CollectionExportFormat>;
};

/** Autogenerated input type of SeatPersonMutation */
export type SeatPersonMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventParticipationId: Scalars['ID']['input'];
  tableSeatId: Scalars['ID']['input'];
};

/** Autogenerated return type of SeatPersonMutation. */
export type SeatPersonMutationPayload = {
  __typename?: 'SeatPersonMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  room?: Maybe<Room>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of SendApprovalEmailMutation */
export type SendApprovalEmailMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  participationId: Scalars['ID']['input'];
};

/** Autogenerated return type of SendApprovalEmailMutation. */
export type SendApprovalEmailMutationPayload = {
  __typename?: 'SendApprovalEmailMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of SendEmailMutation */
export type SendEmailMutationInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  emailId: Scalars['ID']['input'];
  participationId: Scalars['ID']['input'];
  subject?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Autogenerated return type of SendEmailMutation. */
export type SendEmailMutationPayload = {
  __typename?: 'SendEmailMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of SendPreviewMutation */
export type SendPreviewMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SendPreviewMutation. */
export type SendPreviewMutationPayload = {
  __typename?: 'SendPreviewMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  recipients?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

export type StandingParticipant = {
  __typename?: 'StandingParticipant';
  createdAt: Scalars['ISO8601DateTime']['output'];
  eventParticipation: EventParticipation;
  id: Scalars['ID']['output'];
  left: Scalars['Int']['output'];
  room: Room;
  top: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type SubEvent = {
  __typename?: 'SubEvent';
  createdAt: Scalars['ISO8601DateTime']['output'];
  endDate?: Maybe<Scalars['ISO8601Date']['output']>;
  endTime?: Maybe<Scalars['String']['output']>;
  event: Event;
  id: Scalars['ID']['output'];
  inheritParticipants: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  shortName?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['ISO8601Date']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type SubEventParticipation = {
  __typename?: 'SubEventParticipation';
  attended: Scalars['Boolean']['output'];
  cancelled: Scalars['Boolean']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  invited: Scalars['Boolean']['output'];
  participation: EventParticipation;
  response?: Maybe<Response>;
  subEvent: SubEvent;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type SubEventParticipationDetailsInput = {
  attended?: InputMaybe<Scalars['Boolean']['input']>;
  cancelled?: InputMaybe<Scalars['Boolean']['input']>;
  invited?: InputMaybe<Scalars['Boolean']['input']>;
  response?: InputMaybe<Response>;
};

export type SubEventParticipationStatusForBatch = {
  attended: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  invited: Scalars['Boolean']['input'];
  response?: InputMaybe<Response>;
};

export type Table = {
  __typename?: 'Table';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  kind: TableKind;
  left: Scalars['Int']['output'];
  note?: Maybe<Scalars['String']['output']>;
  number: Scalars['Int']['output'];
  room: Room;
  rotation: Scalars['Int']['output'];
  seats: Array<TableSeat>;
  seatsCount: Scalars['Int']['output'];
  seatsCount1: Scalars['Int']['output'];
  seatsCount2?: Maybe<Scalars['Int']['output']>;
  seatsCount3?: Maybe<Scalars['Int']['output']>;
  seatsCount4?: Maybe<Scalars['Int']['output']>;
  top: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum TableKind {
  Board = 'board',
  Hollow = 'hollow',
  Round = 'round',
  Theater = 'theater',
  Ushape = 'ushape'
}

export type TableSeat = {
  __typename?: 'TableSeat';
  createdAt: Scalars['ISO8601DateTime']['output'];
  eventParticipation?: Maybe<EventParticipation>;
  id: Scalars['ID']['output'];
  number: Scalars['Int']['output'];
  table: Table;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** ActsAsTaggableOn::Tag */
export type Tag = {
  __typename?: 'Tag';
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  taggingsCount?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type TagCollection = {
  __typename?: 'TagCollection';
  collection: Array<Tag>;
  export: CollectionExport;
  meta: CollectionMetadata;
};


export type TagCollectionExportArgs = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  format?: InputMaybe<CollectionExportFormat>;
};

export type TagList = {
  __typename?: 'TagList';
  condition: TagListCondition;
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  institutionParticipants: InstitutionCollection;
  kind: TagListKind;
  name: Scalars['String']['output'];
  peopleParticipants: PersonCollection;
  pointPerson?: Maybe<Person>;
  tags: Array<Tag>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


export type TagListInstitutionParticipantsArgs = {
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type TagListPeopleParticipantsArgs = {
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type TagListCollection = {
  __typename?: 'TagListCollection';
  collection: Array<TagList>;
  export: CollectionExport;
  meta: CollectionMetadata;
};


export type TagListCollectionExportArgs = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  format?: InputMaybe<CollectionExportFormat>;
};

export enum TagListCondition {
  And = 'AND',
  Or = 'OR'
}

export enum TagListContactStatus {
  All = 'all',
  NotPrimary = 'not_primary',
  Primary = 'primary'
}

export type TagListContactsFilters = {
  email?: InputMaybe<TagListContactStatus>;
};

export type TagListInstitutionsSort = {
  direction?: InputMaybe<Direction>;
  field?: InputMaybe<Scalars['String']['input']>;
};

export enum TagListKind {
  Contacts = 'CONTACTS',
  Institutions = 'INSTITUTIONS'
}

export type TagListPeopleSort = {
  direction?: InputMaybe<Direction>;
  field?: InputMaybe<TagListPeopleSortFields>;
};

export enum TagListPeopleSortFields {
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME'
}

export type TagListsFilters = {
  /** Can be Person ID or Institution ID */
  participantId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated input type of TakeOutPersonMutation */
export type TakeOutPersonMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventParticipationId: Scalars['ID']['input'];
  roomId: Scalars['ID']['input'];
};

/** Autogenerated return type of TakeOutPersonMutation. */
export type TakeOutPersonMutationPayload = {
  __typename?: 'TakeOutPersonMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  room?: Maybe<Room>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UnseatPersonMutation */
export type UnseatPersonMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  tableSeatId: Scalars['ID']['input'];
};

/** Autogenerated return type of UnseatPersonMutation. */
export type UnseatPersonMutationPayload = {
  __typename?: 'UnseatPersonMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

export type UpdateAccommodationDetails = {
  amountPaid?: InputMaybe<Scalars['String']['input']>;
  bookedOnline?: InputMaybe<Scalars['Boolean']['input']>;
  cancelled?: InputMaybe<Scalars['Boolean']['input']>;
  confirmation?: InputMaybe<Scalars['String']['input']>;
  dietaryRequirements?: InputMaybe<Scalars['String']['input']>;
  earlyCheckIn?: InputMaybe<Scalars['Boolean']['input']>;
  earlyCheckInTime?: InputMaybe<Scalars['String']['input']>;
  earlyCheckInType?: InputMaybe<CheckInOut>;
  endDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  endTime?: InputMaybe<Scalars['String']['input']>;
  extrasPaid?: InputMaybe<Scalars['Boolean']['input']>;
  kosher?: InputMaybe<Scalars['Boolean']['input']>;
  lateCheckOut?: InputMaybe<Scalars['Boolean']['input']>;
  lateCheckOutTime?: InputMaybe<Scalars['String']['input']>;
  lateCheckOutType?: InputMaybe<CheckInOut>;
  lodgeId?: InputMaybe<Scalars['ID']['input']>;
  lodgeRoomId?: InputMaybe<Scalars['ID']['input']>;
  loyaltyCardNumber?: InputMaybe<Scalars['String']['input']>;
  nightsPaid?: InputMaybe<Scalars['String']['input']>;
  paidStatus?: InputMaybe<PaidStatus>;
  pricePerNightAmount?: InputMaybe<Scalars['Float']['input']>;
  pricePerNightCurrency?: InputMaybe<Currency>;
  roomCode?: InputMaybe<Scalars['String']['input']>;
  roomNumber?: InputMaybe<Scalars['String']['input']>;
  roomRequirements?: InputMaybe<Scalars['String']['input']>;
  sharedWithId?: InputMaybe<Scalars['ID']['input']>;
  smoking?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateAffiliationMutation */
export type UpdateAffiliationMutationInput = {
  affiliationId: Scalars['ID']['input'];
  affiliationTypeId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateAffiliationMutation. */
export type UpdateAffiliationMutationPayload = {
  __typename?: 'UpdateAffiliationMutationPayload';
  affiliation?: Maybe<Affiliation>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateAffiliationTypeMutation */
export type UpdateAffiliationTypeMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  wjcSpecific?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of UpdateAffiliationTypeMutation. */
export type UpdateAffiliationTypeMutationPayload = {
  __typename?: 'UpdateAffiliationTypeMutationPayload';
  affiliationType?: Maybe<AffiliationType>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateCapacityMutation */
export type UpdateCapacityMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateCapacityMutation. */
export type UpdateCapacityMutationPayload = {
  __typename?: 'UpdateCapacityMutationPayload';
  capacity?: Maybe<Capacity>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateContactKindMutation */
export type UpdateContactKindMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateContactKindMutation. */
export type UpdateContactKindMutationPayload = {
  __typename?: 'UpdateContactKindMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  contactKind?: Maybe<ContactKind>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateContactsMutation */
export type UpdateContactsMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contactIds: Array<Scalars['ID']['input']>;
  participationId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateContactsMutation. */
export type UpdateContactsMutationPayload = {
  __typename?: 'UpdateContactsMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  participation?: Maybe<EventParticipation>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateContactsMutationV2 */
export type UpdateContactsMutationV2Input = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contacts: Array<ParticipationContactDetails>;
  participationId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateContactsMutationV2. */
export type UpdateContactsMutationV2Payload = {
  __typename?: 'UpdateContactsMutationV2Payload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  participation?: Maybe<EventParticipation>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateCostCenterMutation */
export type UpdateCostCenterMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** ID of the cost center */
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateCostCenterMutation. */
export type UpdateCostCenterMutationPayload = {
  __typename?: 'UpdateCostCenterMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  costCenter?: Maybe<CostCenter>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

export type UpdateCountryDetails = {
  callingCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  deskOfficerId?: InputMaybe<Scalars['ID']['input']>;
  jdCoordinatorId?: InputMaybe<Scalars['ID']['input']>;
  nationalExpertId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated input type of UpdateCountryV2Mutation */
export type UpdateCountryV2MutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: UpdateCountryDetails;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateCountryV2Mutation. */
export type UpdateCountryV2MutationPayload = {
  __typename?: 'UpdateCountryV2MutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Country>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateDetailsMutation */
export type UpdateDetailsMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: EventParticipationDetailsInput;
  participationId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateDetailsMutation. */
export type UpdateDetailsMutationPayload = {
  __typename?: 'UpdateDetailsMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  participation?: Maybe<EventParticipation>;
  success: Scalars['Boolean']['output'];
};

export type UpdateEmailDetails = {
  body?: InputMaybe<Scalars['String']['input']>;
  kind?: InputMaybe<EmailKind>;
  name?: InputMaybe<Scalars['String']['input']>;
  parsedBody?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateEmailMutation */
export type UpdateEmailMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: UpdateEmailDetails;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateEmailMutation. */
export type UpdateEmailMutationPayload = {
  __typename?: 'UpdateEmailMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Email>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateEventCapacitiesMutation */
export type UpdateEventCapacitiesMutationInput = {
  capacityIds: Array<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateEventCapacitiesMutation. */
export type UpdateEventCapacitiesMutationPayload = {
  __typename?: 'UpdateEventCapacitiesMutationPayload';
  capacities?: Maybe<Array<EventCapacity>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

export type UpdateEventDetails = {
  confirmationTag?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  enableAccommodations?: InputMaybe<Scalars['Boolean']['input']>;
  enableCapacities?: InputMaybe<Scalars['Boolean']['input']>;
  enableGroups?: InputMaybe<Scalars['Boolean']['input']>;
  enablePassports?: InputMaybe<Scalars['Boolean']['input']>;
  enableTables?: InputMaybe<Scalars['Boolean']['input']>;
  enableTransportLocations?: InputMaybe<Scalars['Boolean']['input']>;
  enableVoting?: InputMaybe<Scalars['Boolean']['input']>;
  endDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  endTime?: InputMaybe<Scalars['String']['input']>;
  eventTypeId?: InputMaybe<Scalars['ID']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  officialName?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
  wjcEvent?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of UpdateEventDetailsMutation */
export type UpdateEventDetailsMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: UpdateEventDetails;
  eventId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateEventDetailsMutation. */
export type UpdateEventDetailsMutationPayload = {
  __typename?: 'UpdateEventDetailsMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  event?: Maybe<Event>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateEventGroupMutation */
export type UpdateEventGroupMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: EventGroupDetails;
  eventGroupId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateEventGroupMutation. */
export type UpdateEventGroupMutationPayload = {
  __typename?: 'UpdateEventGroupMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  eventGroup?: Maybe<EventGroup>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateEventGroupTypeMutation */
export type UpdateEventGroupTypeMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateEventGroupTypeMutation. */
export type UpdateEventGroupTypeMutationPayload = {
  __typename?: 'UpdateEventGroupTypeMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  eventGroupType?: Maybe<EventGroupType>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateEventLodge */
export type UpdateEventLodgeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: LodgeDetails;
  lodgeId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateEventLodge. */
export type UpdateEventLodgePayload = {
  __typename?: 'UpdateEventLodgePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  lodge?: Maybe<Lodge>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateEventLodgeRoom */
export type UpdateEventLodgeRoomInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: LodgeRoomDetails;
  roomId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateEventLodgeRoom. */
export type UpdateEventLodgeRoomPayload = {
  __typename?: 'UpdateEventLodgeRoomPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  room?: Maybe<LodgeRoomQuantity>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateEventTypeMutation */
export type UpdateEventTypeMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateEventTypeMutation. */
export type UpdateEventTypeMutationPayload = {
  __typename?: 'UpdateEventTypeMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  eventType?: Maybe<EventType>;
  success: Scalars['Boolean']['output'];
};

export type UpdateFormDetails = {
  adminNotification?: InputMaybe<Scalars['Boolean']['input']>;
  adminNotificationEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  closedMessageBody?: InputMaybe<Scalars['String']['input']>;
  headerImageBlobId?: InputMaybe<Scalars['ID']['input']>;
  metaDescription?: InputMaybe<Scalars['String']['input']>;
  metaTitle?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rows?: InputMaybe<Array<Scalars['JSON']['input']>>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<FormStatusEnum>;
  submitButtonLabel?: InputMaybe<Scalars['String']['input']>;
  successMessageBody?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateFormMutation */
export type UpdateFormMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: UpdateFormDetails;
  formId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateFormMutation. */
export type UpdateFormMutationPayload = {
  __typename?: 'UpdateFormMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  form?: Maybe<Form>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateGroupsMutation */
export type UpdateGroupsMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: EventParticipationGroupDetailsInput;
  participationId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateGroupsMutation. */
export type UpdateGroupsMutationPayload = {
  __typename?: 'UpdateGroupsMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  participation?: Maybe<EventParticipation>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateInstitutionCategories */
export type UpdateInstitutionCategoriesInput = {
  categoryIds: Array<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  institutionId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateInstitutionCategories. */
export type UpdateInstitutionCategoriesPayload = {
  __typename?: 'UpdateInstitutionCategoriesPayload';
  categories?: Maybe<Array<InstitutionCategory>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateInstitutionContact */
export type UpdateInstitutionContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contactId: Scalars['ID']['input'];
  details?: InstitutionContactInput;
};

/** Autogenerated return type of UpdateInstitutionContact. */
export type UpdateInstitutionContactPayload = {
  __typename?: 'UpdateInstitutionContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<InstitutionContact>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateInstitutionDetails */
export type UpdateInstitutionDetailsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: InstitutionDetailsInput;
  institutionId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateInstitutionDetails. */
export type UpdateInstitutionDetailsPayload = {
  __typename?: 'UpdateInstitutionDetailsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  institution?: Maybe<Institution>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateInstitutionLocationContact */
export type UpdateInstitutionLocationContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contactId: Scalars['ID']['input'];
  details?: InstitutionLocationContactInput;
};

/** Autogenerated return type of UpdateInstitutionLocationContact. */
export type UpdateInstitutionLocationContactPayload = {
  __typename?: 'UpdateInstitutionLocationContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<InstitutionLocationContact>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateInstitutionLocationMutation */
export type UpdateInstitutionLocationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  institutionLocationId: Scalars['ID']['input'];
  locationFields: InstitutionLocationInput;
};

/** Autogenerated return type of UpdateInstitutionLocationMutation. */
export type UpdateInstitutionLocationMutationPayload = {
  __typename?: 'UpdateInstitutionLocationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  institutionLocation?: Maybe<InstitutionLocation>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateInstitutionMembership */
export type UpdateInstitutionMembershipInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: InstitutionMembershipInput;
  institutionMembershipId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateInstitutionMembership. */
export type UpdateInstitutionMembershipPayload = {
  __typename?: 'UpdateInstitutionMembershipPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  institutionMembership?: Maybe<InstitutionMembership>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateLastVisitMutation */
export type UpdateLastVisitMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  date: Scalars['ISO8601Date']['input'];
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateLastVisitMutation. */
export type UpdateLastVisitMutationPayload = {
  __typename?: 'UpdateLastVisitMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
  user?: Maybe<User>;
};

/** Autogenerated input type of UpdateMembershipContact */
export type UpdateMembershipContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contactId: Scalars['ID']['input'];
  details?: MembershipContactInput;
};

/** Autogenerated return type of UpdateMembershipContact. */
export type UpdateMembershipContactPayload = {
  __typename?: 'UpdateMembershipContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<InstitutionMembershipContact>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateMembershipPosition */
export type UpdateMembershipPositionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details?: MembershipPositionInput;
  positionId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateMembershipPosition. */
export type UpdateMembershipPositionPayload = {
  __typename?: 'UpdateMembershipPositionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  position?: Maybe<InstitutionMembershipPosition>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateMutation */
export type UpdateMutationInput = {
  accommodationId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: UpdateAccommodationDetails;
};

/** Autogenerated return type of UpdateMutation. */
export type UpdateMutationPayload = {
  __typename?: 'UpdateMutationPayload';
  accommodation?: Maybe<Accommodation>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdatePassportMutation */
export type UpdatePassportMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  passportFields: PersonPassportInput;
  passportId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdatePassportMutation. */
export type UpdatePassportMutationPayload = {
  __typename?: 'UpdatePassportMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  passport?: Maybe<Passport>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdatePeopleRelationshipTypeMutation */
export type UpdatePeopleRelationshipTypeMutationInput = {
  category?: InputMaybe<RelationshipTypeEnum>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the relationship type */
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  opposite?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdatePeopleRelationshipTypeMutation. */
export type UpdatePeopleRelationshipTypeMutationPayload = {
  __typename?: 'UpdatePeopleRelationshipTypeMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  relationshipType?: Maybe<RelationshipType>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdatePersonContact */
export type UpdatePersonContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contactId: Scalars['ID']['input'];
  details?: PersonContactInput;
};

/** Autogenerated return type of UpdatePersonContact. */
export type UpdatePersonContactPayload = {
  __typename?: 'UpdatePersonContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<PersonContact>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdatePersonDetailsMutation */
export type UpdatePersonDetailsMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<PersonDetailsInput>;
  personId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdatePersonDetailsMutation. */
export type UpdatePersonDetailsMutationPayload = {
  __typename?: 'UpdatePersonDetailsMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  person?: Maybe<Person>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdatePersonLocationContact */
export type UpdatePersonLocationContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contactId: Scalars['ID']['input'];
  details?: PersonLocationContactInput;
};

/** Autogenerated return type of UpdatePersonLocationContact. */
export type UpdatePersonLocationContactPayload = {
  __typename?: 'UpdatePersonLocationContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<PersonLocationContact>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdatePersonLocationMutation */
export type UpdatePersonLocationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  locationFields: PersonLocationInput;
  personLocationId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdatePersonLocationMutation. */
export type UpdatePersonLocationMutationPayload = {
  __typename?: 'UpdatePersonLocationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  personLocation?: Maybe<PersonLocation>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdatePersonRelationshipMutation */
export type UpdatePersonRelationshipMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  relationshipId: Scalars['ID']['input'];
  relationshipTypeId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdatePersonRelationshipMutation. */
export type UpdatePersonRelationshipMutationPayload = {
  __typename?: 'UpdatePersonRelationshipMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  relationship?: Maybe<Relationship>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdatePersonTitleMutation */
export type UpdatePersonTitleMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

/** Autogenerated return type of UpdatePersonTitleMutation. */
export type UpdatePersonTitleMutationPayload = {
  __typename?: 'UpdatePersonTitleMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  personTitle?: Maybe<PersonTitle>;
  success: Scalars['Boolean']['output'];
};

export type UpdatePresetDetails = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<PresetSortInput>;
};

/** Autogenerated input type of UpdatePresetMutation */
export type UpdatePresetMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: UpdatePresetDetails;
  presetId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdatePresetMutation. */
export type UpdatePresetMutationPayload = {
  __typename?: 'UpdatePresetMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  eventPreset?: Maybe<EventPreset>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateReleaseNoteMutation */
export type UpdateReleaseNoteMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: CreateReleaseNoteDetails;
  releaseNoteId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateReleaseNoteMutation. */
export type UpdateReleaseNoteMutationPayload = {
  __typename?: 'UpdateReleaseNoteMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  releaseNote?: Maybe<ReleaseNote>;
  success: Scalars['Boolean']['output'];
};

export type UpdateRoomDetails = {
  landscape?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateRoomMutation */
export type UpdateRoomMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: UpdateRoomDetails;
  roomId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateRoomMutation. */
export type UpdateRoomMutationPayload = {
  __typename?: 'UpdateRoomMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  room?: Maybe<Room>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateRoomTypeMutation */
export type UpdateRoomTypeMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateRoomTypeMutation. */
export type UpdateRoomTypeMutationPayload = {
  __typename?: 'UpdateRoomTypeMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  roomType?: Maybe<RoomType>;
  success: Scalars['Boolean']['output'];
};

export type UpdateSubEventDetails = {
  endDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  endTime?: InputMaybe<Scalars['String']['input']>;
  inheritParticipants?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateSubEventMutation */
export type UpdateSubEventMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: UpdateSubEventDetails;
  subEventId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateSubEventMutation. */
export type UpdateSubEventMutationPayload = {
  __typename?: 'UpdateSubEventMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  subEvent?: Maybe<SubEvent>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateSubEventParticipationMutation */
export type UpdateSubEventParticipationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: SubEventParticipationDetailsInput;
  subEventParticipationId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateSubEventParticipationMutation. */
export type UpdateSubEventParticipationMutationPayload = {
  __typename?: 'UpdateSubEventParticipationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  subEventParticipation?: Maybe<SubEventParticipation>;
  success: Scalars['Boolean']['output'];
};

export type UpdateTableDetails = {
  kind: Scalars['String']['input'];
  left: Scalars['Int']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  number: Scalars['Int']['input'];
  rotation: Scalars['Int']['input'];
  seatsCount1: Scalars['Int']['input'];
  seatsCount2?: InputMaybe<Scalars['Int']['input']>;
  seatsCount3?: InputMaybe<Scalars['Int']['input']>;
  seatsCount4?: InputMaybe<Scalars['Int']['input']>;
  top: Scalars['Int']['input'];
};

/** Autogenerated input type of UpdateTableMutation */
export type UpdateTableMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: UpdateTableDetails;
  tableId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateTableMutation. */
export type UpdateTableMutationPayload = {
  __typename?: 'UpdateTableMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
  table?: Maybe<Table>;
};

/** Autogenerated input type of UpdateTagListMutation */
export type UpdateTagListMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  condition?: InputMaybe<TagListCondition>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  kind?: InputMaybe<TagListKind>;
  name?: InputMaybe<Scalars['String']['input']>;
  pointPersonId?: InputMaybe<Scalars['ID']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Autogenerated return type of UpdateTagListMutation. */
export type UpdateTagListMutationPayload = {
  __typename?: 'UpdateTagListMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
  tagList?: Maybe<TagList>;
};

/** Autogenerated input type of UpdateTagMutation */
export type UpdateTagMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateTagMutation. */
export type UpdateTagMutationPayload = {
  __typename?: 'UpdateTagMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
  tag?: Maybe<Tag>;
};

/** Autogenerated input type of UpdateTransportLocationMutation */
export type UpdateTransportLocationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventTransportLocationId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateTransportLocationMutation. */
export type UpdateTransportLocationMutationPayload = {
  __typename?: 'UpdateTransportLocationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  eventTransportLocation?: Maybe<EventTransportLocation>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateTransportMutation */
export type UpdateTransportMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: EventParticipationTransportDetails;
  participationId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateTransportMutation. */
export type UpdateTransportMutationPayload = {
  __typename?: 'UpdateTransportMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
  transport?: Maybe<EventTransport>;
};

/** Autogenerated input type of UpdateTravelBudgetMutation */
export type UpdateTravelBudgetMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: EventParticipationTravelBudgetDetails;
  participationId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateTravelBudgetMutation. */
export type UpdateTravelBudgetMutationPayload = {
  __typename?: 'UpdateTravelBudgetMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateVotingGroupMutation */
export type UpdateVotingGroupMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  votingGroupId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateVotingGroupMutation. */
export type UpdateVotingGroupMutationPayload = {
  __typename?: 'UpdateVotingGroupMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
  votingGroup?: Maybe<VotingGroup>;
};

export type UpdateVotingKeyDetails = {
  name?: InputMaybe<Scalars['String']['input']>;
  slots?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of UpdateVotingKeyMutation */
export type UpdateVotingKeyMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details: UpdateVotingKeyDetails;
  votingKeyId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateVotingKeyMutation. */
export type UpdateVotingKeyMutationPayload = {
  __typename?: 'UpdateVotingKeyMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean']['output'];
  votingKey?: Maybe<VotingKey>;
};

/** User */
export type User = {
  __typename?: 'User';
  createdAt: Scalars['ISO8601DateTime']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  releaseNoteLastVisit?: Maybe<Scalars['ISO8601Date']['output']>;
  role: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type UserCollection = {
  __typename?: 'UserCollection';
  collection: Array<User>;
  export: CollectionExport;
  meta: CollectionMetadata;
};


export type UserCollectionExportArgs = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  format?: InputMaybe<CollectionExportFormat>;
};

export type VotingGroup = {
  __typename?: 'VotingGroup';
  createdAt: Scalars['ISO8601DateTime']['output'];
  event: Array<Event>;
  id: Scalars['ID']['output'];
  keys: Array<VotingKey>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type VotingKey = {
  __typename?: 'VotingKey';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parent: Array<VotingGroup>;
  slots: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

<template class="settings-pls">
  <header class="flex px-6 lg:px-12 py-4 items-center bg-white h-16">
    <WJCLogo class="mr-10 flex-none" />
    <nav class="flex gap-2 lg:gap-6 header-nav">
      <GumPolicyGuard :resource="PolicyResourceEnum.People" action="list">
        <RouterLink :to="{ name: 'people' }" v-bind="commonProps">
          <PeopleIcon />
          People
        </RouterLink>
      </GumPolicyGuard>
      <GumPolicyGuard :resource="PolicyResourceEnum.Institutions" action="list">
        <RouterLink :to="{ name: 'institutions' }" v-bind="commonProps">
          <InstitutionIcon />
          Institutions
        </RouterLink>
      </GumPolicyGuard>
      <GumPolicyGuard :resource="PolicyResourceEnum.Events" action="list">
        <RouterLink :to="{ name: 'events' }" v-bind="commonProps">
          <EventIcon />
          Events
        </RouterLink>
      </GumPolicyGuard>
      <GumPolicyGuard :resource="PolicyResourceEnum.TagLists" action="list">
        <RouterLink :to="{ name: 'lists' }" v-bind="commonProps">
          <ListsIcon />
          Lists
        </RouterLink>
      </GumPolicyGuard>
      <GumPolicyGuard :resource="PolicyResourceEnum.ReleaseNotes" action="list">
        <RouterLink
          :to="{ name: 'release_notes' }"
          v-bind="commonProps"
          @click="updateReleaseNotesVisit"
        >
          <GreenStar v-if="me?.releaseNoteLastVisit == lastNoteDate" />
          <GreenStarPulse v-else />
          What's new
        </RouterLink>
      </GumPolicyGuard>
    </nav>
    <div class="ml-auto flex items-center gap-4">
      <div class="flex flex-row items-center settings-dropdown">
        <Dropdown trigger="click" placement="bottomRight">
          <div class="flex items-center cursor-pointer">
            <SettingsIcon class="mr-1" />
            <span class="hidden lg:inline text-sm">Settings</span>
          </div>
          <template #overlay>
            <Menu class="settings-menu">
              <GumPolicyGuard :resource="PolicyResourceEnum.User" action="list">
                <MenuItem>
                  <RouterLink :to="{ name: 'settings_users' }"> </RouterLink>
                  Users
                </MenuItem>
              </GumPolicyGuard>
              <GumPolicyGuard :resource="PolicyResourceEnum.Countries" action="list">
                <MenuItem>
                  <RouterLink :to="{ name: 'settings_countries' }"> </RouterLink>
                  Countries
                </MenuItem>
              </GumPolicyGuard>
              <GumPolicyGuard :resource="PolicyResourceEnum.Others" action="list">
                <MenuItemGroup>
                  <template #title>Manage Fields</template>
                  <!-- Keep those sorted in alphabetical order -->
                  <ManageFieldItem route="affiliation_types">Affiliate Types</ManageFieldItem>
                  <ManageFieldItem route="capacities">Capacities</ManageFieldItem>
                  <ManageFieldItem route="contact_types">Contact Types</ManageFieldItem>
                  <ManageFieldItem route="cost_centers">Cost Centers</ManageFieldItem>
                  <ManageFieldItem route="event_types">Event Types</ManageFieldItem>
                  <ManageFieldItem route="group_types">Group Types</ManageFieldItem>
                  <ManageFieldItem route="person_titles">Title Types</ManageFieldItem>
                  <ManageFieldItem route="relationship_types">Relationship Types</ManageFieldItem>
                  <ManageFieldItem route="room_types">Room Types</ManageFieldItem>
                  <ManageFieldItem route="tags">Tags</ManageFieldItem>
                </MenuItemGroup>
              </GumPolicyGuard>
            </Menu>
          </template>
        </Dropdown>
      </div>
      <CurrentUserMenuVue />
    </div>
  </header>
</template>

<script lang="ts" setup>
import { RouterLink } from 'vue-router'
import { computed } from 'vue'
import WJCLogo from './WJCLogo.vue'
import {
  PeopleIcon,
  EventIcon,
  ListsIcon,
  InstitutionIcon,
  SettingsIcon,
  GreenStar,
  GreenStarPulse,
  GumPolicyGuard
} from '@/components/ui'
import CurrentUserMenuVue from './CurrentUserMenu.vue'
import Dropdown from 'ant-design-vue/es/dropdown'
import Menu, { MenuItem, MenuItemGroup } from 'ant-design-vue/es/menu'
import ManageFieldItem from './ManageFieldItem.vue'
import { PolicyResourceEnum } from '@/graphql/app'
import { getReleaseNotes } from '@/pages/ReleaseNotes/data/getAllReleaseNotes.gql'
import { updateLastVisitMutation } from './updateUserLastVisit.gql'
import { useAction } from '@/services/forms'
import { getMe } from './getMe.gql'

const { me, refetch } = getMe()

const { notes } = getReleaseNotes({})

const lastNoteDate = computed(() => {
  return notes.value[0]?.date
})

const commonProps = {
  class: 'main-header-link',
  activeClass: 'main-header-active-link'
}

const { updateLastVisit } = updateLastVisitMutation()
const { perform: performUpdate } = useAction({
  action: () => updateLastVisit({ userId: me.value!.id, date: lastNoteDate.value.toString() }),
  onSuccess: () => {
    refetch()
  }
})

const updateReleaseNotesVisit = () => {
  if (me.value?.releaseNoteLastVisit !== lastNoteDate.value) {
    performUpdate()
  }
}
</script>

<style lang="less">
.settings-dropdown {
  .ant-dropdown-trigger {
    @apply px-2 py-1 rounded-lg text-black text-opacity-60 font-semibold;

    &:hover {
      @apply text-opacity-100;
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
  .ant-dropdown-open {
    background-color: rgba(0, 0, 0, 0.04);
    color: black;
  }
}

.ant-dropdown-menu {
  @apply rounded-md;
}
.ant-dropdown-menu-item {
  @apply rounded-md font-normal;

  &:hover {
    background-color: #ecf7ff;
  }
}

.ant-dropdown-menu {
  @apply p-1;
}

a.main-header-link {
  @apply px-2 py-1 rounded-lg font-semibold text-black text-opacity-60;
  @apply flex flex-row items-center gap-x-1 text-sm no-underline;

  svg {
    width: 16px;
    height: 16px;
    @apply opacity-80;
  }

  &:hover,
  &.main-header-active-link {
    color: black;
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.main-header-active-link {
  background-color: rgba(0, 0, 0, 0.04);
  @apply text-opacity-100;

  svg {
    @apply opacity-100;
  }
}

.settings-icon svg {
  width: 12px;
  height: 12px;
}
</style>
